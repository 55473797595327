import {
  GeoPoint
} from '../index';

declare var Object: any;
export interface ActiveClientSignatureViewInterface {
  "id": number;
  "tenantId": number;
  "userId": number;
  "consumerId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  "requestID": string;
  "manifestRecId"?: string;
  "scheduledTime"?: string;
  "pickupTime"?: string;
  "dropoffTime"?: string;
  "arrivedTime"?: string;
  "imgFileId"?: string;
  "type": string;
  "captured": number;
  "unableToSign": number;
  "oneWayOnly": number;
  "dateTimeOfService": Date;
  "dateTime": Date;
  "marker"?: string;
  "status"?: string;
  "validationState": string;
  "location"?: GeoPoint;
  "meta": any;
  "note"?: string;
  "vdate"?: any;
  "weekday"?: number;
  "vServiceType"?: string;
  "vTripId"?: string;
  "vTotalMealsUnits"?: number;
  "vUnits"?: number;
  "vAppName"?: string;
  "hash"?: string;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
}

export class ActiveClientSignatureView implements ActiveClientSignatureViewInterface {
  "id": number;
  "tenantId": number;
  "userId": number;
  "consumerId": number;
  "employeeId": number;
  "vehicleId": number;
  "requestID": string;
  "manifestRecId": string;
  "scheduledTime": string;
  "pickupTime": string;
  "dropoffTime": string;
  "arrivedTime": string;
  "imgFileId": string;
  "type": string;
  "captured": number;
  "unableToSign": number;
  "oneWayOnly": number;
  "dateTimeOfService": Date;
  "dateTime": Date;
  "marker": string;
  "status": string;
  "validationState": string;
  "location": GeoPoint;
  "meta": any;
  "note": string;
  "vdate": any;
  "weekday": number;
  "vServiceType": string;
  "vTripId": string;
  "vTotalMealsUnits": number;
  "vUnits": number;
  "vAppName": string;
  "hash": string;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  constructor(data?: ActiveClientSignatureViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ActiveClientSignatureView`.
   */
  public static getModelName() {
    return "ActiveClientSignatureView";
  }

  /**
  * This method creates an instance of ActiveClientSignatureView for dynamic purposes.
  **/
  public static factory(data: ActiveClientSignatureViewInterface): ActiveClientSignatureView{
    return new ActiveClientSignatureView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ActiveClientSignatureView',
      plural: 'ActiveClientSignatureViews',
      path: 'ActiveClientSignatureViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "manifestRecId": {
          name: 'manifestRecId',
          type: 'string'
        },
        "scheduledTime": {
          name: 'scheduledTime',
          type: 'string'
        },
        "pickupTime": {
          name: 'pickupTime',
          type: 'string'
        },
        "dropoffTime": {
          name: 'dropoffTime',
          type: 'string'
        },
        "arrivedTime": {
          name: 'arrivedTime',
          type: 'string'
        },
        "imgFileId": {
          name: 'imgFileId',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "captured": {
          name: 'captured',
          type: 'number'
        },
        "unableToSign": {
          name: 'unableToSign',
          type: 'number'
        },
        "oneWayOnly": {
          name: 'oneWayOnly',
          type: 'number'
        },
        "dateTimeOfService": {
          name: 'dateTimeOfService',
          type: 'Date'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "marker": {
          name: 'marker',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "validationState": {
          name: 'validationState',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
        "vServiceType": {
          name: 'vServiceType',
          type: 'string'
        },
        "vTripId": {
          name: 'vTripId',
          type: 'string'
        },
        "vTotalMealsUnits": {
          name: 'vTotalMealsUnits',
          type: 'number'
        },
        "vUnits": {
          name: 'vUnits',
          type: 'number'
        },
        "vAppName": {
          name: 'vAppName',
          type: 'string'
        },
        "hash": {
          name: 'hash',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}

<app-drop-down-grid
  [dataSource]="ds"
  [gridColumns]="gridColumns"
  [placeholder]="placeholder"
  [showClearButton]="true"
  [valueExpr]="'id'"
  [displayExpr]="employeeHelper.displayExpr"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (valueChanged)="valueChanged.emit($event)"
  [popupWidth]="350"
  [popupHeight]="300"
></app-drop-down-grid>

import * as tslib_1 from "tslib";
import { EventEmitter, QueryList } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryComponent } from 'devextreme-angular/ui/validation-summary';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import { oc } from 'ts-optchain';
import { ABaseModelLoaderComponent } from './a-base-model-loader.component';
export class ABaseFormComponent extends ABaseModelLoaderComponent {
    constructor(logger, fb, dss, helper) {
        super(logger, dss);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.helper = helper;
        this.formConfigMap = new Map();
        this.selectedTabIdx = 0;
        this.resetableForm = true;
        this.collapsibleForm = true;
        this.validateFails = new EventEmitter();
        this.beforeSubmitting = new EventEmitter();
        this.afterSubmitted = new EventEmitter();
        this.beforeSetFormValues = new EventEmitter();
        this.afterSetFormValues = new EventEmitter();
        this.submittingChange = new EventEmitter();
        this.formLoadingChange = new EventEmitter();
        this._formLoading = false;
        this._submitting = false;
        this.buildForm();
    }
    get formLoading() {
        return this._formLoading;
    }
    set formLoading(value) {
        if (value !== this._formLoading) {
            this._formLoading = value;
            this.formLoadingChange.emit(value);
        }
    }
    get submitting() {
        return this._submitting;
    }
    set submitting(value) {
        if (value !== this._submitting) {
            this._submitting = value;
            this.submittingChange.emit(value);
        }
    }
    get observeModels() {
        return [];
    }
    get mongoDatePaths() {
        return [];
    }
    canDeactivate(e) {
        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        console.log(e);
        return oc(this.form).pristine(true);
    }
    addFormArrayItem(path, idx) {
        const fa = this.form.get(path);
        const fg = this.fb.group(cloneDeep(this.formConfigMap.get(path)));
        if (idx !== undefined)
            fa.insert(idx, fg);
        else
            fa.push(fg);
    }
    removeFormArrayItem(path, idx) {
        const fa = this.form.get(path);
        fa.removeAt(idx);
    }
    submitFormAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.error = null;
            const isValid = yield this.validateAsync();
            try {
                this.submitting = true;
                if (isValid) {
                    const data = Object.assign({}, this.form.value);
                    const [instance, id] = yield this.submitDataAsync(data);
                    this.modelId = id;
                    return instance;
                }
                else {
                    this.validateFails.emit();
                }
            }
            catch (err) {
                this.error = err;
                this.modelLoadingError.emit(err);
                // notify(this.errorMessage, 'error', 5000);
            }
            finally {
                setTimeout(() => (this.submitting = false));
            }
        });
    }
    reset() {
        this.error = null;
        setTimeout(() => {
            // this.buildForm();
            this.setFormValues(this.model, true, true);
        });
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            try {
                this.formLoading = true;
                if (this.modelId) {
                    yield this.beforeSetFormValuesAsync(model);
                    this.beforeSetFormValues.emit(model);
                    // this.buildForm();
                    this.setFormValues(model, true, true);
                    yield this.afterSetFormValuesAsync(model, this.form);
                    this.afterSetFormValues.emit(model);
                }
            }
            finally {
                setTimeout(() => (this.formLoading = false));
            }
        });
    }
    beforeSetFormValuesAsync(model) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    afterSetFormValuesAsync(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    deleteRelated(model, modelProp, path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const RelatedModel = modelProp.constructor;
            const ModelDef = this.ModelClass.getModelDefinition();
            const RelatedModelDef = RelatedModel.getModelDefinition();
            const rel = ModelDef.relations[path];
            if (rel && rel.modelThrough) {
                return this.dss.getApi(this.ModelClass)['unlink' + RelatedModelDef.plural](model.id, modelProp.id).toPromise();
            }
            else {
                return this.dss.getApi(RelatedModel).deleteById(modelProp.id).toPromise();
            }
        });
    }
    deleteRelatedRecursive(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return Promise.all(this.helper.getPathFormArrayPairs(form, this.formConfigMap).map(([path, formArray]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // find removed related entities
                return Promise.all(get(model, path, [])
                    .filter(modelProp => modelProp.constructor && modelProp.constructor.getModelName)
                    .map(modelProp => {
                    const formProp = formArray.controls.find(itm => itm.value.id === modelProp.id);
                    if (!formProp) {
                        return this.deleteRelated(model, modelProp, path);
                    }
                    return this.deleteRelatedRecursive(modelProp, formProp);
                }));
            })));
        });
    }
    beforeSubmittingAsync(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.model) {
                yield this.deleteRelatedRecursive(this.model, this.form);
            }
        });
    }
    afterSubmittedAsync(data, obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    processFormValueAsync(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.helper.processFormValueAsync(this.model, data, {
                datePaths: this.dateFields,
                mongoDatePaths: this.mongoDatePaths,
            });
        });
    }
    setFormValues(model, isReset = true, resetValidators = false, subForm = null) {
        if (!this.form) {
            return;
        }
        const f = subForm || this.form;
        if (resetValidators && isReset && !subForm) {
            if (this.validationGroups && this.validationGroups.length) {
                this.validationGroups.forEach(vg => vg && vg.instance && vg.instance.reset());
            }
            if (this.validators && this.validators.length) {
                this.validators.forEach(v => v && v.instance && v.instance.reset());
            }
        }
        if (this.validationSummary) {
            this.validationSummary.items = [];
        }
        if (!model) {
            f.reset();
        }
        else {
            if (isReset) {
                f.reset(model);
            }
            else {
                f.patchValue(model);
            }
        }
        // set FormArray controls
        this.helper.getPathFormArrayPairs(f, this.formConfigMap).forEach(([path, ac]) => {
            const items = get(model, path, []);
            while (ac.length > 0) {
                ac.removeAt(0);
            }
            items.forEach(item => {
                const fg = this.fb.group(cloneDeep(this.formConfigMap.get(path)));
                this.setFormValues(item, isReset, resetValidators, fg);
                ac.push(fg);
            });
        });
        if (isReset) {
            f.markAsPristine();
            f.markAsUntouched();
        }
    }
    validateAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let validateResults = [];
            this.helper.withEachFormControl(this.form, c => {
                c.markAsTouched({ onlySelf: true });
            });
            this.form.updateValueAndValidity();
            if (this.validationGroups && this.validationGroups.length) {
                validateResults = [
                    ...validateResults,
                    ...this.validationGroups.map(vg => vg && vg.instance && vg.instance.validate()),
                ];
            }
            if (this.validators && this.validators.length) {
                validateResults = [...validateResults, ...this.validators.map(v => v && v.instance && v.instance.validate())];
            }
            const invalidResults = validateResults.filter(res => !res.isValid);
            // invalidResults.forEach((res) =>
            //   console.error(res.validationRules || res.validationRule));
            // if (!this.form.valid) {
            //   console.error(this.getControlErrors(this.form));
            // }
            // console.log(invalidResults.length, this.form.valid);
            // console.log(invalidResults);
            return isEmpty(invalidResults) && this.form.valid;
        });
    }
    getControlErrors(control) {
        if (control instanceof FormControl) {
            return control.errors;
        }
        else {
            return (control instanceof FormGroup
                ? Object.entries(control.controls).map(([f, fc]) => [f, this.getControlErrors(fc)])
                : control instanceof FormArray
                    ? control.controls.map((fc, idx) => [`${idx}`, this.getControlErrors(fc)])
                    : [])
                .map(([f, fe]) => {
                if (isArray(fe)) {
                    return fe.map(([_f, _fe]) => [`${f}.${_f}`, _fe]);
                }
                else {
                    return [[f, fe]];
                }
            })
                .reduce((a, b) => a.concat(b), [])
                .filter(([f, fe]) => !!fe);
        }
    }
    submitDataAsync(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            data = yield this.processFormValueAsync(data);
            yield this.beforeSubmittingAsync(data);
            this.beforeSubmitting.emit(data);
            const model = new this.ModelClass(data);
            // this.logger.log(model);
            const [instance, id] = yield this.helper.saveModelAsync(model);
            this.form.markAsPristine();
            this.form.markAsUntouched();
            yield this.afterSubmittedAsync(data, instance);
            this.afterSubmitted.emit(instance);
            return [instance, id];
        });
    }
}

import {
  Consumer,
  PersAlert
} from '../index';

declare var Object: any;
export interface PersDeviceInterface {
  "_id"?: any;
  "status": string;
  "type": string;
  "phoneNumber"?: string;
  "notes"?: string;
  "make"?: string;
  "model"?: string;
  "imei"?: string;
  "installationDetails"?: string;
  "deliveredDate"?: Date;
  "deliveryConfirmation"?: string;
  "shippingLabelFile"?: string;
  "installationDate"?: Date;
  "virtualTrainingDate"?: Date;
  "virtualTrainingConductedBy"?: string;
  "virtualTrainingNotes"?: string;
  "initialLiveTestDate"?: Date;
  "lastSuccessfulTestingAdaptiveEquipment"?: Date;
  "lastSuccessfulTestDate"?: Date;
  "consumerId"?: number;
  consumer?: Consumer;
  alerts?: PersAlert[];
}

export class PersDevice implements PersDeviceInterface {
  "_id": any;
  "status": string;
  "type": string;
  "phoneNumber": string;
  "notes": string;
  "make": string;
  "model": string;
  "imei": string;
  "installationDetails": string;
  "deliveredDate": Date;
  "deliveryConfirmation": string;
  "shippingLabelFile": string;
  "installationDate": Date;
  "virtualTrainingDate": Date;
  "virtualTrainingConductedBy": string;
  "virtualTrainingNotes": string;
  "initialLiveTestDate": Date;
  "lastSuccessfulTestingAdaptiveEquipment": Date;
  "lastSuccessfulTestDate": Date;
  "consumerId": number;
  consumer: Consumer;
  alerts: PersAlert[];
  constructor(data?: PersDeviceInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PersDevice`.
   */
  public static getModelName() {
    return "PersDevice";
  }

  /**
  * This method creates an instance of PersDevice for dynamic purposes.
  **/
  public static factory(data: PersDeviceInterface): PersDevice{
    return new PersDevice(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PersDevice',
      plural: 'PersDevices',
      path: 'PersDevices',
      idName: '_id',
      properties: {
        "_id": {
          name: '_id',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "make": {
          name: 'make',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "imei": {
          name: 'imei',
          type: 'string'
        },
        "installationDetails": {
          name: 'installationDetails',
          type: 'string'
        },
        "deliveredDate": {
          name: 'deliveredDate',
          type: 'Date'
        },
        "deliveryConfirmation": {
          name: 'deliveryConfirmation',
          type: 'string'
        },
        "shippingLabelFile": {
          name: 'shippingLabelFile',
          type: 'string'
        },
        "installationDate": {
          name: 'installationDate',
          type: 'Date'
        },
        "virtualTrainingDate": {
          name: 'virtualTrainingDate',
          type: 'Date'
        },
        "virtualTrainingConductedBy": {
          name: 'virtualTrainingConductedBy',
          type: 'string'
        },
        "virtualTrainingNotes": {
          name: 'virtualTrainingNotes',
          type: 'string'
        },
        "initialLiveTestDate": {
          name: 'initialLiveTestDate',
          type: 'Date'
        },
        "lastSuccessfulTestingAdaptiveEquipment": {
          name: 'lastSuccessfulTestingAdaptiveEquipment',
          type: 'Date'
        },
        "lastSuccessfulTestDate": {
          name: 'lastSuccessfulTestDate',
          type: 'Date'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
      },
      relations: {
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'consumerId',
          keyTo: 'id'
        },
        alerts: {
          name: 'alerts',
          type: 'PersAlert[]',
          model: 'PersAlert',
          relationType: 'hasMany',
                  keyFrom: 'phoneNumber',
          keyTo: 'deviceNum'
        },
      }
    }
  }
}

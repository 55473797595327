import { castArray } from 'lodash-es';
import head from 'lodash-es/head';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import isString from 'lodash-es/isString';
import isUndefined from 'lodash-es/isUndefined';
import set from 'lodash-es/set';
import tail from 'lodash-es/tail';
import transform from 'lodash-es/transform';
import { oc } from 'ts-optchain';

/**
 * Map flat object to tree style.
 * prop_prop_prop => prop.prop.prop
 */
export function flatToTreeObject(flatObject: any, separator: string = '_'): any {
  return Object.keys(flatObject)
    .map((field): [string, string[]] => [field, field.split(separator)])
    .reduce((o, [field, path]) => set(o, path, flatObject[field]), {});
}

/**
 * Get all object key paths by key name
 */
export function getPathsByKey_old(source: any, targetKey: string | RegExp, recursive: boolean = true): string[][] {
  const paths: string[][] = [];

  if (isArray(source) && recursive) {
    source
      .map((item, idx) => getPathsByKey_old(item, targetKey, recursive).map(path => ['' + idx].concat(path)))
      .filter(subPaths => subPaths.length > 0)
      .forEach(subPaths => paths.push(...subPaths));
  } else if (isPlainObject(source) || isObjectLike(source)) {
    if (isString(targetKey)) {
      if (targetKey in source) {
        paths.push([targetKey as string]);
      }
    } else if (targetKey) {
      Object.keys(source).forEach(key => {
        if (new RegExp(targetKey).test(key)) {
          paths.push([key]);
        }
      });
    }

    if (recursive) {
      Object.keys(source).forEach(key => {
        getPathsByKey_old(source[key], targetKey, recursive)
          .map(path => [key].concat(path))
          .forEach(path => paths.push(path));
      });
    }
  }

  return paths;
}

export function getPathsByKey(
  source: any,
  targetKey: string | RegExp | any,
  options?: {
    recursive?: boolean;
    terminals?: boolean;
  },
): string[][] {
  const paths: string[][] = [];

  if (isArray(source)) {
    source.forEach((item, idx) => {
      let subs: string[][] = [];

      if (oc(options).recursive(true)) {
        subs = getPathsByKey(item, targetKey, options).filter(_path => !isEmpty(_path));

        subs.map(_path => [idx.toString()].concat(_path)).forEach(_path => paths.push(_path));
      }

      // if (subs.length) {
      //   paths.push([idx.toString()]);
      // }
    });
  } else if (isPlainObject(source) || isObjectLike(source)) {
    Object.keys(source).forEach(key => {
      let subs: string[][] = [];
      if (oc(options).recursive(true)) {
        subs = getPathsByKey(source[key], targetKey, options).filter(_path => !isEmpty(_path));

        subs.map(_path => [key].concat(_path)).forEach(_path => paths.push(_path));
      }

      if (!oc(options).terminals(false) || (oc(options).terminals(false) && subs.length === 0)) {
        if (isString(targetKey)) {
          if (key === targetKey) {
            paths.push([key]);
          }
        } else if (targetKey instanceof RegExp) {
          if (new RegExp(targetKey).test(key)) {
            paths.push([key]);
          }
        } else {
          paths.push([key]);
        }
      }
    });
  }

  return paths;
}

export function getPathsByKeyV2(
  source: any,
  targetKeys: (string | RegExp | null) | (string | RegExp | null)[],
  options?: {
    recursive?: boolean;
    terminals?: boolean;
    exclude?: (string | RegExp) | (string | RegExp)[];
  },
): string[][] {
  const paths: string[][] = [];

  if (isArray(source)) {
    source.forEach((item, idx) => {
      if (oc(options).recursive(true)) {
        getPathsByKeyV2(item, targetKeys, options)
          .filter(p => !isEmpty(p))
          .map(p => [idx.toString(), ...p])
          .forEach(p => paths.push(p));
      }
    });
  } else if (isPlainObject(source) || isObjectLike(source)) {
    Object.keys(source).forEach(key => {
      const _isObjKeyItem = isObjectLike(source[key]);

      if (!oc(options).terminals(false) || (oc(options).terminals(false) && !_isObjKeyItem)) {
        if (
          !castArray(oc(options).exclude([])).some(xKey => {
            if (isString(xKey)) {
              return xKey === key;
            } else if (xKey instanceof RegExp) {
              return new RegExp(xKey).test(key);
            }
          })
        ) {
          const pass = castArray(targetKeys).some(tKey => {
            if (isString(tKey)) {
              return tKey === key;
            } else if (tKey instanceof RegExp) {
              return new RegExp(tKey).test(key);
            } else {
              return true;
            }
          });

          if (pass) paths.push([key]);
        }
      }

      if (_isObjKeyItem && oc(options).recursive(true)) {
        getPathsByKeyV2(source[key], targetKeys, options)
          .filter(p => p.length > 0)
          .map(p => [key, ...p])
          .forEach(p => paths.push(p));
      }
    });
  }

  return paths;
}

export function differenceDeep<T>(object, base: T, nullEqToUndefined?: boolean) {
  const diff = transform<any, any>(
    object,
    (acc, curr, key) => {
      if (!isEqual(curr, base[key])) {
        if (nullEqToUndefined && isNil(curr) && isNil(base[key])) {
          return;
        }

        acc[key] =
          (isPlainObject(curr) || isObjectLike(curr)) && (isPlainObject(base[key]) || isObjectLike(base[key]))
            ? differenceDeep(curr, base[key], nullEqToUndefined)
            : curr;
      }
    },
    {},
  ) as T;

  return transform<any, any>(
    base,
    (acc, curr, key) => {
      if (isUndefined(object[key])) {
        if (nullEqToUndefined && isNil(curr) && isNil(object[key])) {
          return;
        }

        acc[key] = null;
      }
    },
    diff,
  ) as T;
}

export function combinations(...arrays: any[][]) {
  const res: any[] = [];

  const _h = head(arrays) || [];
  const _t = tail(arrays) || [];

  _h.forEach(elm => {
    if (_t.length) {
      combinations(..._t).forEach(rest => {
        res.push([elm, ...rest]);
      });
    } else {
      res.push([elm]);
    }
  });

  return res;
}


declare var Object: any;
export interface MealsAdcSignatureStatsInterface {
  "id"?: string;
  "vdate"?: any;
  "tenantId": number;
  "consumerId"?: number;
  "validationState": string;
  "count": number;
  "units"?: number;
  "mu1"?: number;
  "mu2"?: number;
  "mu3"?: number;
  "mu4"?: number;
  "mu5"?: number;
}

export class MealsAdcSignatureStats implements MealsAdcSignatureStatsInterface {
  "id": string;
  "vdate": any;
  "tenantId": number;
  "consumerId": number;
  "validationState": string;
  "count": number;
  "units": number;
  "mu1": number;
  "mu2": number;
  "mu3": number;
  "mu4": number;
  "mu5": number;
  constructor(data?: MealsAdcSignatureStatsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MealsAdcSignatureStats`.
   */
  public static getModelName() {
    return "MealsAdcSignatureStats";
  }

  /**
  * This method creates an instance of MealsAdcSignatureStats for dynamic purposes.
  **/
  public static factory(data: MealsAdcSignatureStatsInterface): MealsAdcSignatureStats{
    return new MealsAdcSignatureStats(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MealsAdcSignatureStats',
      plural: 'MealsAdcSignatureStats',
      path: 'MealsAdcSignatureStats',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "validationState": {
          name: 'validationState',
          type: 'string'
        },
        "count": {
          name: 'count',
          type: 'number'
        },
        "units": {
          name: 'units',
          type: 'number'
        },
        "mu1": {
          name: 'mu1',
          type: 'number'
        },
        "mu2": {
          name: 'mu2',
          type: 'number'
        },
        "mu3": {
          name: 'mu3',
          type: 'number'
        },
        "mu4": {
          name: 'mu4',
          type: 'number'
        },
        "mu5": {
          name: 'mu5',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}

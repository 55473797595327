import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import get from 'lodash-es/get';
import isDate from 'lodash-es/isDate';
import isEmpty from 'lodash-es/isEmpty';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import isString from 'lodash-es/isString';
import last from 'lodash-es/last';
import set from 'lodash-es/set';
import unset from 'lodash-es/unset';
import { utc } from 'moment';
import { getPathsByKey } from '../../../classes/utils/object.utils';
import { deferredAsPromise } from '../../../classes/utils/promise.utils';
import { asShortDate } from '../../../classes/utils/time.utils';
import { SDKModels } from '../../../sdk';
import { ExtSDKModels } from '../../ext-sdk/services/ext-sdk-models.service';
import { DataSourceService } from '../../my-common/services/datasource.service';

@Injectable()
export class FormHelperService<M> {
  constructor(
    @Inject(SDKModels) protected models: ExtSDKModels,
    protected dss: DataSourceService,
  ) {}

  async processFormValueAsync(
    model: M,
    data: any,
    options: { datePaths: string[]; mongoDatePaths: string[] },
  ): Promise<any> {
    // convert Date to string format 'YYYY-MM-DD'
    (options.datePaths || []).forEach(path => {
      const val = get(data, path, undefined);
      if (isDate(val) || (isString(val) && !isEmpty(val))) {
        set(data, path, asShortDate(val));
      }
    });

    // convert Date to mongo 'YYYY-MM-DDT12:00:00.000Z'
    (options.mongoDatePaths || []).forEach(path => {
      const val = get(data, path, undefined);
      if (isDate(val) || (isString(val) && !isEmpty(val))) {
        set(data, path, utc(asShortDate(val)).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toISOString());
      }
    });

    // copy data field json
    const dataPaths = [...getPathsByKey(model, 'data'), ...getPathsByKey(model, 'meta')];

    dataPaths
      .filter(path => get(data, path, undefined))
      .filter(path => get(model, path, undefined))
      .filter(path => isPlainObject(get(model, path, undefined)) || isObjectLike(get(model, path, undefined)))
      .map(path => [path, get(model, path, {}), get(data, path, {})])
      .map(([path, oldValue, newValue]) => [path, Object.assign({}, oldValue, newValue)])
      .forEach(([path, value]) => set(data, path, value));

    // unset all '_' prefixed properties
    const prefixPaths = getPathsByKey(data, /^_.*/);

    prefixPaths
      .filter(path => last(path) !== '_id')
      .forEach(path => {
        unset(data, path);
      });

    return data;
  }

  async saveModelAsync(model: M & any): Promise<[M, string | number]> {
    const store = this.dss.getStore(model.constructor);
    const modelName = model.constructor.getModelName();
    const keyName = this.models.getIdName(modelName);

    //    console.log(modelName, keyName, model[keyName]);

    let id;
    let values;

    if (model[keyName]) {
      [values, id] = await deferredAsPromise(store.update(model[keyName], model));
    } else {
      [values, id] = await deferredAsPromise(store.insert(model));
      // model[keyName] = values;
    }
    // console.log('saveModelAsync', [values, id]);

    return [values, id];
  }

  getPathFormArrayPairs(form: FormGroup, formConfigMap: Map<string, any>): [string, FormArray][] {
    return Array.from(formConfigMap.keys())
      .reverse() // from root to children
      .map<[string, FormArray]>(path => [path, form.get(path) as FormArray])
      .filter(([path, ctrl]) => ctrl && ctrl instanceof FormArray);
  }

  withEachFormControl(form: FormGroup | FormArray, fn: (ctrl: FormControl) => void): void {
    Object.keys(form.controls).forEach(key => {
      const ctrl = form.get(key);

      if (ctrl instanceof FormArray) {
        this.withEachFormControl(ctrl, fn);
      } else if (ctrl instanceof FormGroup) {
        this.withEachFormControl(ctrl, fn);
      } else if (ctrl instanceof FormControl) {
        fn(ctrl);
      }
    });
  }
}


declare var Object: any;
export interface DocumentViewInterface {
  "id": number;
  "documentTypeId": number;
  "ownerId": number;
  "objectType": string;
  "objectId": number;
  "name": string;
  "notes"?: string;
  "meta": any;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "documentType_id"?: number;
  "documentType_objectFQN"?: string;
  "documentType_name"?: string;
  "documentType_order"?: number;
  "documentType_frequent"?: number;
  "documentType_predefined"?: number;
  "documentType_inactive"?: number;
  "documentType_visibility"?: string;
  "documentType_type"?: string;
  "documentType_defaultNotes"?: string;
  "documentType_expAlert"?: number;
  "documentType_missingDocAlert"?: number;
  "files_0_id"?: number;
  "files_0_originalFilename"?: string;
  "files_0_container"?: string;
  "files_0_filename"?: string;
  "files_0_uploadedAt"?: Date;
  "files_0_uploadedById"?: number;
  "files_0_documentId"?: number;
}

export class DocumentView implements DocumentViewInterface {
  "id": number;
  "documentTypeId": number;
  "ownerId": number;
  "objectType": string;
  "objectId": number;
  "name": string;
  "notes": string;
  "meta": any;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "documentType_id": number;
  "documentType_objectFQN": string;
  "documentType_name": string;
  "documentType_order": number;
  "documentType_frequent": number;
  "documentType_predefined": number;
  "documentType_inactive": number;
  "documentType_visibility": string;
  "documentType_type": string;
  "documentType_defaultNotes": string;
  "documentType_expAlert": number;
  "documentType_missingDocAlert": number;
  "files_0_id": number;
  "files_0_originalFilename": string;
  "files_0_container": string;
  "files_0_filename": string;
  "files_0_uploadedAt": Date;
  "files_0_uploadedById": number;
  "files_0_documentId": number;
  constructor(data?: DocumentViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentView`.
   */
  public static getModelName() {
    return "DocumentView";
  }

  /**
  * This method creates an instance of DocumentView for dynamic purposes.
  **/
  public static factory(data: DocumentViewInterface): DocumentView{
    return new DocumentView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentView',
      plural: 'DocumentViews',
      path: 'DocumentViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "documentTypeId": {
          name: 'documentTypeId',
          type: 'number'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "documentType_id": {
          name: 'documentType_id',
          type: 'number'
        },
        "documentType_objectFQN": {
          name: 'documentType_objectFQN',
          type: 'string'
        },
        "documentType_name": {
          name: 'documentType_name',
          type: 'string'
        },
        "documentType_order": {
          name: 'documentType_order',
          type: 'number'
        },
        "documentType_frequent": {
          name: 'documentType_frequent',
          type: 'number'
        },
        "documentType_predefined": {
          name: 'documentType_predefined',
          type: 'number'
        },
        "documentType_inactive": {
          name: 'documentType_inactive',
          type: 'number'
        },
        "documentType_visibility": {
          name: 'documentType_visibility',
          type: 'string'
        },
        "documentType_type": {
          name: 'documentType_type',
          type: 'string'
        },
        "documentType_defaultNotes": {
          name: 'documentType_defaultNotes',
          type: 'string'
        },
        "documentType_expAlert": {
          name: 'documentType_expAlert',
          type: 'number'
        },
        "documentType_missingDocAlert": {
          name: 'documentType_missingDocAlert',
          type: 'number'
        },
        "files_0_id": {
          name: 'files_0_id',
          type: 'number'
        },
        "files_0_originalFilename": {
          name: 'files_0_originalFilename',
          type: 'string'
        },
        "files_0_container": {
          name: 'files_0_container',
          type: 'string'
        },
        "files_0_filename": {
          name: 'files_0_filename',
          type: 'string'
        },
        "files_0_uploadedAt": {
          name: 'files_0_uploadedAt',
          type: 'Date'
        },
        "files_0_uploadedById": {
          name: 'files_0_uploadedById',
          type: 'number'
        },
        "files_0_documentId": {
          name: 'files_0_documentId',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}

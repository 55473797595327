import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select } from '@ngrx/store';
import dxList from 'devextreme/ui/list';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { IEnabledComponents } from 'src/app/store/reducers/sign/info';
import { CommonService } from '../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { ABaseComponent } from '../../../shared/modules/ui/components/abstract/a-base.component';
import { Facility, FacilityApi, LoggerService, MyUser, MyUserApi } from '../../../shared/sdk';
import { getCurrentTenant, getEnabledComponents, getUser, isSignedIn } from '../../../store/reducers/sign';
import { ListGroup, ListItem, SIDEBAR_ITEMS } from './sidebar-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends ABaseComponent implements OnInit, OnDestroy {
  items$: Observable<Array<any>>;

  signedUser$: Observable<MyUser>;
  isSignedIn$: Observable<boolean>;
  myRoles$: Observable<string[]>;
  enabledComponents$: Observable<IEnabledComponents>;
  tenantType$: Observable<string>;
  tenantId$: Observable<number>;

  constructor(
    protected logger: LoggerService,
    private common: CommonService,
    public config: ConfigService,
    public route: ActivatedRoute,
    private userApi: MyUserApi,
    private facilityApi: FacilityApi,
  ) {
    super(logger);

    this.signedUser$ = this.common.store.pipe(select(getUser));
    this.isSignedIn$ = this.common.store.pipe(select(isSignedIn));
    this.tenantId$ = this.common.store.pipe(select(getCurrentTenant));
    this.myRoles$ = this.config.roles$;
    this.enabledComponents$ = this.common.store.pipe(select(getEnabledComponents));

    this.tenantType$ = this.common.store.pipe(
      select(getCurrentTenant),
      switchMap(tenantId => this.facilityApi.findById<Facility>(tenantId)),
      catchError(err => of(null)),
      map(tenant => tenant && tenant.type),
    );

    this.buildItems();
  }

  list_onContentReady(e: any) {
    const list: dxList = e.component;

    // setTimeout(function () {
    //   const items = list.option('items');
    //   for (let i = 0; i < items.length; i++)
    //     list.collapseGroup(i);
    // }, 100);
  }

  list_onGroupRendered(e: any) {
    const list: dxList = e.component;
    list.collapseGroup(e.groupIndex);
  }

  private buildItems() {
    this.items$ = combineLatest([this.myRoles$, this.enabledComponents$, this.tenantId$, this.tenantType$]).pipe(
      map(([roles, enabledComponents, tenantId, tenantType]) => {
        const items = cloneDeep(SIDEBAR_ITEMS);
        // console.log(roles, tenantId, tenantType);
        return this.filterItems(items, tenantId, tenantType, roles, enabledComponents);
      }),
      takeUntil(this.$onDestroy$),
    );

    this.items$.subscribe();
  }

  private filterItems(
    items: Array<ListGroup | ListItem>,
    tenantId: number,
    tenantType: string,
    roles: string[],
    enabledComponents: IEnabledComponents,
  ) {
    return items
      .filter(itm => isEmpty(itm.enabledComponent) || enabledComponents[itm.enabledComponent])
      .filter(itm => isEmpty(itm.acl) || ['SU', ...itm.acl].find(role => roles.includes(role)))
      .filter(itm => isEmpty(itm.den) || !itm.den.find(role => roles.includes(role)))
      .filter(
        itm =>
          (isEmpty(itm.tenantTypes) || itm.tenantTypes.includes(tenantType)) &&
          (isEmpty(itm.tenantTypesExcl) || !itm.tenantTypesExcl.includes(tenantType)),
      )
      .filter(itm => isEmpty(itm.tenantIds) || itm.tenantIds.includes(tenantId))
      .filter(itm => !itm.fn || itm.fn(this.config))
      .filter(itm => (itm.tenantIds || []).includes(-1) || tenantId !== -1)
      .map(itm => {
        if ('items' in itm) {
          (itm as ListGroup).items = this.filterItems(
            (itm as ListGroup).items,
            tenantId,
            tenantType,
            roles,
            enabledComponents,
          );
        }
        return itm;
      });
  }
}

import { Injectable } from '@angular/core';
import DxDataGrid from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import fromPairs from 'lodash-es/fromPairs';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isObjectLike from 'lodash-es/isObjectLike';
import isPlainObject from 'lodash-es/isPlainObject';
import isString from 'lodash-es/isString';
import set from 'lodash-es/set';
import take from 'lodash-es/take';
import { flatToTreeObject, getPathsByKey } from '../../../classes/utils/object.utils';

//

export interface GridHelperHandleOptions {
  filter?: (field: string, value: any) => boolean;
  mapper?: (field: string, value: any) => [string, any];
  processor?: (any) => any;
  notifyErrors?: boolean;

  flatToTreeObject?: boolean;
  copyIdsOnSaving?: boolean;
  copyJsonDataOnSaving?: boolean;
  mapEmptyStringToNull?: boolean;
  selectRowOnEdit?: boolean;

  onGridRefresh?: () => void;
}

@Injectable()
export class GridHelperService {
  defaultOptions: any = {
    allowColumnResizing: true,
    allowColumnReordering: true,
    errorRowEnabled: true,

    showBorders: true,
    showColumnLines: true,
    showRowLines: true,
    rowAlternationEnabled: true,

    remoteOperations: {
      filtering: true,
      sorting: true,
      paging: true,
    },

    editing: {
      mode: 'row',
      allowAdding: true,
      allowUpdating: true,
      allowDeleting: true,
    },

    loadPanel: {
      enabled: true,
    },

    columnChooser: {
      mode: 'select',
      enabled: true,
    },

    searchPanel: {
      visible: true,
    },

    headerFilter: {
      visible: false,
    },

    filterRow: {
      visible: true,
      applyFilter: 'auto',
    },

    filterPanel: {
      visible: true,
    },

    sorting: {
      mode: 'multiple',
    },

    selection: {
      mode: 'single',
      selectAllMode: 'page',
      allowSelectAll: true,
    },

    paging: {
      pageSize: 50,
    },

    pager: {
      visible: true,
      showInfo: true,
      showNavigationButtons: true,
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
    },
  };

  constructor() {
    DxDataGrid.defaultOptions({ options: this.defaultOptions });
  }

  handle(gridInstance: DxDataGrid, options: GridHelperHandleOptions = {}): void {
    const defaultOptions: GridHelperHandleOptions = {
      flatToTreeObject: true,
      copyIdsOnSaving: true,
      copyJsonDataOnSaving: true,
      selectRowOnEdit: true,
    };
    options = { ...defaultOptions, ...options };

    // does not work correctly
    // if (options.stateStoreGUID) {
    //   const stateStoring = this.sss.buildOptions(options.stateStoreGUID);
    //   gridInstance.option({stateStoring});
    // }

    const events = {
      disposing: (e: { component: DxDataGrid; element: any }) => {
        Object.keys(events).forEach(eventName => e.component.off(eventName));
      },

      dataErrorOccurred: e => {
        if (options.notifyErrors) {
          notify(e.error, 'error', 5000);
        }
      },

      initNewRow: (e: { component: DxDataGrid; element: any; data: any }) => {
        // console.log(e);
        e.component.selectRows([], false);
      },

      editingStart: (e: {
        component: DxDataGrid;
        element: any;
        data: any;
        key: any;
        cancel: boolean | Promise<boolean>;
        column: any;
      }) => {
        // console.log(e);
        if (options.selectRowOnEdit) {
          e.component.selectRows([e.key], false);
        }
      },

      rowInserting: (e: { component: DxDataGrid; element: any; data: any; cancel: boolean | Promise<boolean> }) => {
        // console.log(e);
        e.data = this.processFormData(e.data, {}, options);
      },

      rowUpdating: (e: {
        component: DxDataGrid;
        element: any;
        oldData: any;
        newData: any;
        key: any;
        cancel: boolean | Promise<boolean>;
      }) => {
        // console.log(e);
        e.newData = this.processFormData(e.newData, e.oldData, options);
      },

      toolbarPreparing: (e: { component: DxDataGrid; element: any; toolbarOptions: any }) => {
        e.toolbarOptions.items.push(
          //          {
          //          name: 'clearFilter',
          //          locateInMenu: 'auto',
          //          location: 'after',
          //          widget: 'dxButton',
          //          showText: 'inMenu',
          //          options: {
          //            icon: 'clear',
          //            text: 'Clear filters',
          //            hint: 'Clears all filters applied',
          //            onClick: () => {
          //              e.component.clearFilter('row');
          //              e.component.clearFilter('header');
          //              e.component.clearFilter('search');
          //            },
          //          }
          //        },
          {
            name: 'refresh',
            locateInMenu: 'auto',
            location: 'after',
            sortIndex: 99,
            widget: 'dxButton',
            showText: 'inMenu',
            options: {
              icon: 'refresh',
              text: 'Refresh',
              hint: 'Refresh',
              onClick: options.onGridRefresh ? options.onGridRefresh : () => e.component.refresh(),
            },
          },
        );
      },
    };

    gridInstance.on(events);
  }

  /**
   * 1) Filters fields key/value pairs;
   * 2) Maps key/value pair to new one.
   */
  private prepareFormData(
    data: any,
    filter: (field: string, value: any) => boolean = (f, v) => true,
    mapper: (field: string, value: any) => [string, any] = (f, v) => [f, v],
  ): any {
    return fromPairs(
      Object.entries(data)
        .filter(([field, value]) => (filter ? filter(field, value) : true))
        .map(([field, value]) => (mapper ? mapper(field, value) : [field, value])),
    );
  }

  /**
   * Final form data processing step.
   * 1) Converts flat object path to tree structure; prop_prop_prop => prop.prop.prop
   * 2) Copy all `id` fields from old to new data;
   */
  private processFormData(newData: any, oldData: any = {}, options: GridHelperHandleOptions = {}): any {
    let _oldData = this.prepareFormData(oldData, options.filter, options.mapper);
    let _newData = this.prepareFormData(newData, options.filter, options.mapper);

    if (options.mapEmptyStringToNull) {
      Object.keys(_newData)
        .map(field => [field, _newData[field]])
        .filter(([field, value]) => isString(value) && isEmpty(value))
        .forEach(([field, value]) => (_newData[field] = null));
    }

    if (options.flatToTreeObject) {
      _oldData = flatToTreeObject(_oldData, '_');
      _newData = flatToTreeObject(_newData, '_');
    }

    if (options.copyIdsOnSaving) {
      const idPaths1 = getPathsByKey(_oldData, 'id');
      const idPaths2 = getPathsByKey(_oldData, '_id');

      [...idPaths1, ...idPaths2]
        .filter(path => !isEmpty(get(_newData, take(path, path.length - 1))))
        .map(path => [path, get(_oldData, path, undefined)])
        .filter(([path, value]) => !!value)
        .forEach(([path, value]) => set(_newData, path, value));
    }

    if (options.copyJsonDataOnSaving) {
      const dataPaths = [...getPathsByKey(_oldData, 'data'), ...getPathsByKey(_oldData, 'meta')];

      dataPaths
        .filter(path => get(_newData, path, undefined))
        .filter(path => get(_oldData, path, undefined))
        .filter(path => isPlainObject(get(_oldData, path, undefined)) || isObjectLike(get(_oldData, path, undefined)))
        .map(path => [path, get(_oldData, path, {}), get(_newData, path, {})])
        .map(([path, oldValue, newValue]) => [path, Object.assign({}, oldValue, newValue)])
        .forEach(([path, value]) => set(_newData, path, value));
    }
    if (options.processor) {
      _newData = options.processor(_newData);
    }

    return _newData;
  }
}


declare var Object: any;
export interface DocumentFieldsViewInterface {
  "id"?: number;
  "documentTypeId"?: number;
  "ownerId"?: number;
  "objectType"?: string;
  "objectId"?: number;
  "name"?: string;
  "notes"?: string;
  "meta"?: any;
  "ctime"?: Date;
  "utime"?: Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "dt_id": number;
  "dt_objectFQN": string;
  "dt_name": string;
  "dt_order": number;
  "dt_frequent": number;
  "dt_predefined": number;
  "dt_inactive": number;
  "dt_visibility": string;
  "dt_type": string;
  "dt_defaultNotes"?: string;
  "dt_expAlert": number;
  "dt_missingDocAlert": number;
  "dt_dtime"?: Date;
  "cfv_id"?: number;
  "cfv_customFieldId"?: number;
  "cfv_objectType"?: string;
  "cfv_objectId"?: number;
  "cfv_valueBool"?: number;
  "cfv_valueInt"?: number;
  "cfv_valueFloat"?: number;
  "cfv_valueString"?: string;
  "cfv_valueDate"?: any;
  "cfv_valueTime"?: string;
  "cfv_valueDatetime"?: Date;
  "cfv_valueText"?: string;
  "cfv_valueJSON"?: any;
  "cfv_value"?: string;
  "cf_id"?: number;
  "cf_objectId"?: number;
  "cf_objectType"?: string;
  "cf_field"?: string;
  "cf_label"?: string;
  "cf_type"?: string;
  "cf_optional"?: number;
  "cf_order"?: number;
  "cf_predefined"?: number;
  "cf_meta"?: any;
}

export class DocumentFieldsView implements DocumentFieldsViewInterface {
  "id": number;
  "documentTypeId": number;
  "ownerId": number;
  "objectType": string;
  "objectId": number;
  "name": string;
  "notes": string;
  "meta": any;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "dt_id": number;
  "dt_objectFQN": string;
  "dt_name": string;
  "dt_order": number;
  "dt_frequent": number;
  "dt_predefined": number;
  "dt_inactive": number;
  "dt_visibility": string;
  "dt_type": string;
  "dt_defaultNotes": string;
  "dt_expAlert": number;
  "dt_missingDocAlert": number;
  "dt_dtime": Date;
  "cfv_id": number;
  "cfv_customFieldId": number;
  "cfv_objectType": string;
  "cfv_objectId": number;
  "cfv_valueBool": number;
  "cfv_valueInt": number;
  "cfv_valueFloat": number;
  "cfv_valueString": string;
  "cfv_valueDate": any;
  "cfv_valueTime": string;
  "cfv_valueDatetime": Date;
  "cfv_valueText": string;
  "cfv_valueJSON": any;
  "cfv_value": string;
  "cf_id": number;
  "cf_objectId": number;
  "cf_objectType": string;
  "cf_field": string;
  "cf_label": string;
  "cf_type": string;
  "cf_optional": number;
  "cf_order": number;
  "cf_predefined": number;
  "cf_meta": any;
  constructor(data?: DocumentFieldsViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentFieldsView`.
   */
  public static getModelName() {
    return "DocumentFieldsView";
  }

  /**
  * This method creates an instance of DocumentFieldsView for dynamic purposes.
  **/
  public static factory(data: DocumentFieldsViewInterface): DocumentFieldsView{
    return new DocumentFieldsView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentFieldsView',
      plural: 'DocumentFieldsViews',
      path: 'DocumentFieldsViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "documentTypeId": {
          name: 'documentTypeId',
          type: 'number'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "dt_id": {
          name: 'dt_id',
          type: 'number'
        },
        "dt_objectFQN": {
          name: 'dt_objectFQN',
          type: 'string'
        },
        "dt_name": {
          name: 'dt_name',
          type: 'string'
        },
        "dt_order": {
          name: 'dt_order',
          type: 'number'
        },
        "dt_frequent": {
          name: 'dt_frequent',
          type: 'number'
        },
        "dt_predefined": {
          name: 'dt_predefined',
          type: 'number'
        },
        "dt_inactive": {
          name: 'dt_inactive',
          type: 'number'
        },
        "dt_visibility": {
          name: 'dt_visibility',
          type: 'string'
        },
        "dt_type": {
          name: 'dt_type',
          type: 'string'
        },
        "dt_defaultNotes": {
          name: 'dt_defaultNotes',
          type: 'string'
        },
        "dt_expAlert": {
          name: 'dt_expAlert',
          type: 'number'
        },
        "dt_missingDocAlert": {
          name: 'dt_missingDocAlert',
          type: 'number'
        },
        "dt_dtime": {
          name: 'dt_dtime',
          type: 'Date'
        },
        "cfv_id": {
          name: 'cfv_id',
          type: 'number'
        },
        "cfv_customFieldId": {
          name: 'cfv_customFieldId',
          type: 'number'
        },
        "cfv_objectType": {
          name: 'cfv_objectType',
          type: 'string'
        },
        "cfv_objectId": {
          name: 'cfv_objectId',
          type: 'number'
        },
        "cfv_valueBool": {
          name: 'cfv_valueBool',
          type: 'number'
        },
        "cfv_valueInt": {
          name: 'cfv_valueInt',
          type: 'number'
        },
        "cfv_valueFloat": {
          name: 'cfv_valueFloat',
          type: 'number'
        },
        "cfv_valueString": {
          name: 'cfv_valueString',
          type: 'string'
        },
        "cfv_valueDate": {
          name: 'cfv_valueDate',
          type: 'any'
        },
        "cfv_valueTime": {
          name: 'cfv_valueTime',
          type: 'string'
        },
        "cfv_valueDatetime": {
          name: 'cfv_valueDatetime',
          type: 'Date'
        },
        "cfv_valueText": {
          name: 'cfv_valueText',
          type: 'string'
        },
        "cfv_valueJSON": {
          name: 'cfv_valueJSON',
          type: 'any'
        },
        "cfv_value": {
          name: 'cfv_value',
          type: 'string'
        },
        "cf_id": {
          name: 'cf_id',
          type: 'number'
        },
        "cf_objectId": {
          name: 'cf_objectId',
          type: 'number'
        },
        "cf_objectType": {
          name: 'cf_objectType',
          type: 'string'
        },
        "cf_field": {
          name: 'cf_field',
          type: 'string'
        },
        "cf_label": {
          name: 'cf_label',
          type: 'string'
        },
        "cf_type": {
          name: 'cf_type',
          type: 'string'
        },
        "cf_optional": {
          name: 'cf_optional',
          type: 'number'
        },
        "cf_order": {
          name: 'cf_order',
          type: 'number'
        },
        "cf_predefined": {
          name: 'cf_predefined',
          type: 'number'
        },
        "cf_meta": {
          name: 'cf_meta',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}

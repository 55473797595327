import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { LegacyActivityLogComponent } from './components/legacy-activity-log/legacy-activity-log.component';
import { PersComponent } from './containers/pers/pers.component';

@NgModule({
  declarations: [LegacyActivityLogComponent, PersComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
})
export class PersModule {}

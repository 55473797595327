<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset" *ngIf="selectedTabIdx === 0">

    <div class="dx-field">
      <div class="dx-field-label">Vehicle ID (Active Vehicles Only) *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="vehicleDso$ | async"
          [displayExpr]="vehicle_displayExpression"
          valueExpr="id"
          placeholder="Select..."
          (onSelectionChanged)="vehicle_onSelectionChanged($event)"></dx-select-box>
      </div>
    </div>
  </div>

  <p>
    <dx-validation-summary></dx-validation-summary>
  </p>

  <!--    <p class="alert-danger" *ngIf="errorMessage">-->
  <!--      {{ errorMessage }}-->
  <!--    </p>-->
</dx-validation-group>


declare var Object: any;
export interface PersonViewInterface {
  "id": number;
  "contactId": number;
  "firstname": string;
  "lastname": string;
  "middlename"?: string;
  "dob"?: any;
  "sex"?: string;
  "nickname"?: string;
  "notes"?: string;
  "data": any;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "contact_id"?: number;
  "contact_notes"?: string;
  "contact_addresses_0_id"?: string;
  "contact_addresses_0_street"?: string;
  "contact_addresses_0_city"?: string;
  "contact_addresses_0_state"?: string;
  "contact_addresses_0_zip"?: string;
  "contact_phones_0_id"?: string;
  "contact_phones_0_value"?: string;
  "contact_emails_0_id"?: string;
  "contact_emails_0_value"?: string;
  "consumer_id"?: number;
  "employee_id"?: number;
}

export class PersonView implements PersonViewInterface {
  "id": number;
  "contactId": number;
  "firstname": string;
  "lastname": string;
  "middlename": string;
  "dob": any;
  "sex": string;
  "nickname": string;
  "notes": string;
  "data": any;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "contact_id": number;
  "contact_notes": string;
  "contact_addresses_0_id": string;
  "contact_addresses_0_street": string;
  "contact_addresses_0_city": string;
  "contact_addresses_0_state": string;
  "contact_addresses_0_zip": string;
  "contact_phones_0_id": string;
  "contact_phones_0_value": string;
  "contact_emails_0_id": string;
  "contact_emails_0_value": string;
  "consumer_id": number;
  "employee_id": number;
  constructor(data?: PersonViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PersonView`.
   */
  public static getModelName() {
    return "PersonView";
  }

  /**
  * This method creates an instance of PersonView for dynamic purposes.
  **/
  public static factory(data: PersonViewInterface): PersonView{
    return new PersonView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PersonView',
      plural: 'PersonViews',
      path: 'PersonViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "contactId": {
          name: 'contactId',
          type: 'number'
        },
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "middlename": {
          name: 'middlename',
          type: 'string'
        },
        "dob": {
          name: 'dob',
          type: 'any'
        },
        "sex": {
          name: 'sex',
          type: 'string'
        },
        "nickname": {
          name: 'nickname',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "contact_id": {
          name: 'contact_id',
          type: 'number'
        },
        "contact_notes": {
          name: 'contact_notes',
          type: 'string'
        },
        "contact_addresses_0_id": {
          name: 'contact_addresses_0_id',
          type: 'string'
        },
        "contact_addresses_0_street": {
          name: 'contact_addresses_0_street',
          type: 'string'
        },
        "contact_addresses_0_city": {
          name: 'contact_addresses_0_city',
          type: 'string'
        },
        "contact_addresses_0_state": {
          name: 'contact_addresses_0_state',
          type: 'string'
        },
        "contact_addresses_0_zip": {
          name: 'contact_addresses_0_zip',
          type: 'string'
        },
        "contact_phones_0_id": {
          name: 'contact_phones_0_id',
          type: 'string'
        },
        "contact_phones_0_value": {
          name: 'contact_phones_0_value',
          type: 'string'
        },
        "contact_emails_0_id": {
          name: 'contact_emails_0_id',
          type: 'string'
        },
        "contact_emails_0_value": {
          name: 'contact_emails_0_value',
          type: 'string'
        },
        "consumer_id": {
          name: 'consumer_id',
          type: 'number'
        },
        "employee_id": {
          name: 'employee_id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}

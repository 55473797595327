import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import { LoadOptions } from 'devextreme/data/load_options';
import query from 'devextreme/data/query';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import head from 'lodash-es/head';
import isDate from 'lodash-es/isDate';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import sortBy from 'lodash-es/sortBy';
import toPairs from 'lodash-es/toPairs';
import moment, { utc } from 'moment';
import { oc } from 'ts-optchain';
//
import { environment } from '../../../../environments/environment';
import {
  dxStoreInsertHooks,
  dxStoreLoadHooks,
  dxStoreRemoveHooks,
  dxStoreUpdateHooks,
} from '../../../shared/classes/loopback-custom-store/generic/store.utils';
import { asShortDate, asTime } from '../../../shared/classes/utils/time.utils';
import {
  Address,
  Config,
  ConfigApi,
  Employee,
  EmployeeView,
  Facility,
  LoopBackFilter,
  Note,
  Phone,
  TripManifest,
  TripManifestApi,
  TripManifestRec,
} from '../../../shared/sdk';
import { CommonService } from '../../../shared/modules/my-common/services/common.service';
import { ConfigProps, ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../shared/modules/my-common/services/pusher.service';
import { UploadHelperService } from '../../../shared/modules/ui/services/upload-helper.service';
import { HelperService as ConsumerHelperService } from '../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../employee/services/helper.service';
import { HelperService as VehicleHelperService } from '../../vehicle/services/helper.service';
import { DESTINATIONS, DESTINATIONS_FOR_BASE } from '../classes/enums';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import uniqBy from 'lodash-es/uniqBy';
import { Observable, of } from 'rxjs';
import StringSimilarity from 'string-similarity';

export interface ManifestValidationGroup {
  code: string;
  error: string;
  recHint: string;
  recs: any[];
  priority?: 'danger' | 'danger-crossed' | 'warning';
}

@Injectable()
export class HelperService {
  // TODO: remove hardcode
  // static readonly DRIVER_POSITION_NAMES = ['Driver', 'CDL Driver', 'Supervisor'];
  static readonly TIME_INTERVAL = 5;
  static readonly MAX_TRIPS = 99;

  static readonly REC_FIELD_MAP = {
    time: 't',
    trip: 'tr',
    seat: 's',
    isRoundTrip: 'rt',
    origin: 'o',
    destination: 'd',
    dailyNote: 'dn',

    vehicleId: 'v',
    employeeId: 'e',
    escortId: 'esc',
    consumerId: 'c',
    addressId: 'aid',

    broker: 'b',
    tripId: 'tId',
    brokerNote: 'bn',
    appointmentTime: 'at',
    pickUpTime: 'put',
    dropOffTime: 'dot',
    serviceType: 'st',
    mealsUnits1: 'mu1',
    mealsUnits2: 'mu2',
    cancelled: 'x',

    oneTimeTrip: 'ot',

    vehicle: '__vehicle',

    employee: '__employee',
    _employee_fullName: '__employee_fullName',

    escort: '__escort',
    _escort_fullName: '__escort_fullName',

    consumer: '__consumer',
    _consumer_fullName: '__consumer_fullName',
  };

  static readonly FIELD_VALIDATION_CODES_MAP = {
    [HelperService.REC_FIELD_MAP.destination]: ['NO_DESTINATION'],
    [HelperService.REC_FIELD_MAP.time]: ['NO_TIME'],
    [HelperService.REC_FIELD_MAP.trip]: ['NO_TRIP'],
    [HelperService.REC_FIELD_MAP.tripId]: [
      'INVALID_TRIP_ID',
      'NOT_MATCH_APPOINTMENT',
      'NOT_MATCH_CONSUMER',
      'NOT_MATCH_ADDR_CONSUMER',
      'DUPLICATE_TRIPIDS',
    ],
    [HelperService.REC_FIELD_MAP.seat]: ['NO_SEAT', 'DUPLICATE_SEATS'],
    [HelperService.REC_FIELD_MAP.employeeId]: [
      'NOT_EXIST_EMPLOYEES',
      'NOT_ACTIVE_EMPLOYEES',
      'VEHICLE_MULTI_ASSIGNMENTS',
      'NO_ACCESS_EMPLOYEES',
    ],
    [HelperService.REC_FIELD_MAP.vehicleId]: ['VEHICLE_SEVERAL_ASSIGNMENTS', 'EMPLOYEE_VEHICLE_NO_ASSIGNMENT'],
    [HelperService.REC_FIELD_MAP.consumerId]: [
      'NOT_EXIST_CONSUMERS',
      'NOT_ACTIVE_CONSUMERS',
      'DUPLICATE',
      'NO_RESIDENCE_ROUTE',
      'NO_FACILITY_ROUTE',
      'NO_ACTIVE_MEALS_AUTH',
      'PENDING_CONSUMER',
    ],
  };

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(TripManifestApi) public api: TripManifestApi,
    @Inject(CommonService) private common: CommonService,
    @Inject(ConfigService) private config: ConfigService,
    @Inject(DataSourceService) private dss: DataSourceService,
    @Inject('Window') public window: Window,
    @Inject(EmployeeHelperService) private employeeHelper: EmployeeHelperService,
    @Inject(ConsumerHelperService) private consumerHelper: ConsumerHelperService,
    @Inject(VehicleHelperService) private vehicleHelper: VehicleHelperService,
    private pusher: PusherService,
    private uploadHelper: UploadHelperService,
  ) {}

  get getTimeInterval(): number {
    return HelperService.TIME_INTERVAL;
  }

  get getDriverPositionNames(): string[] {
    return this.config.get(ConfigProps.driverList, []);
  }

  get getMaxTrips(): number {
    return HelperService.MAX_TRIPS;
  }

  get getRecFieldMap() {
    return HelperService.REC_FIELD_MAP;
  }

  manifest_saveFieldMapper = (field: string, value: any): [string, any] => {
    if (field === HelperService.REC_FIELD_MAP.time && isDate(value)) {
      value = asTime(value);
    }

    return [field, value];
  };

  async buildArrayStoreAsync(manifest: TripManifest): Promise<ArrayStore> {
    const helper = this;

    const data: TripManifestRec[] = (manifest.data || []).filter(rec => {
      const cId = rec[HelperService.REC_FIELD_MAP.consumerId];
      const seat = rec[HelperService.REC_FIELD_MAP.seat];
      return oc(cId)(0) > 0 || seat !== -1; // TODO: remove hardcode
    });

    const vFilter: LoopBackFilter = {} as LoopBackFilter;

    const eFilter: LoopBackFilter = {
      fields: ['id', 'status', 'personId', 'tenantIds'],
      include: [
        {
          relation: 'person',
          scope: {
            fields: ['firstname', 'lastname', 'contactId'],
          },
        },
      ],
    } as LoopBackFilter;

    const cFilter: LoopBackFilter = {
      fields: { data: false },
      include: [
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC', limit: 1 },
        },
        {
          relation: 'person',
          scope: {
            fields: ['firstname', 'lastname', 'contactId'],
            include: [
              {
                relation: 'contact',
                scope: {
                  fields: ['id'],
                  include: ['addresses', 'phones', 'emails'],
                },
              },
            ],
          },
        },
      ],
    } as LoopBackFilter;

    const prepareRec = (rec: TripManifestRec, vehicleMap, employeeMap, escortMap, consumerMap) => {
      rec[HelperService.REC_FIELD_MAP.vehicle] = rec.v ? vehicleMap[rec.v] : null;
      rec[HelperService.REC_FIELD_MAP.employee] = rec.e ? employeeMap[rec.e] : null;
      rec[HelperService.REC_FIELD_MAP.escort] = rec.esc ? escortMap[rec.esc] : null;
      rec[HelperService.REC_FIELD_MAP.consumer] = rec.c ? consumerMap[rec.c] : null;
      rec[HelperService.REC_FIELD_MAP.tripId] = oc(rec).tId('');
      rec[HelperService.REC_FIELD_MAP.broker] = oc(rec).b('');

      Object.defineProperty(rec, HelperService.REC_FIELD_MAP._employee_fullName, {
        get() {
          const self: TripManifestRec = this;
          return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.employee));
        },
      });

      Object.defineProperty(rec, HelperService.REC_FIELD_MAP._escort_fullName, {
        get() {
          const self: TripManifestRec = this;
          return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.escort));
        },
      });

      Object.defineProperty(rec, HelperService.REC_FIELD_MAP._consumer_fullName, {
        get() {
          const self: TripManifestRec = this;
          return helper.consumerHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.consumer));
        },
      });

      (rec as any).toResidence = function () {
        const self: TripManifestRec = this;
        return self[HelperService.REC_FIELD_MAP.destination] === 'RESIDENCE';
      }.bind(rec);

      (rec as any).getVehicleTitle = function () {
        const self: TripManifestRec = this;
        return helper.vehicleHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.vehicle));
      }.bind(rec);

      (rec as any).getEmployeeFullName = function () {
        const self: TripManifestRec = this;
        return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.employee));
      }.bind(rec);

      (rec as any).getEscortFullName = function () {
        const self: TripManifestRec = this;
        return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.escort));
      }.bind(rec);

      (rec as any).getConsumerFullName = function () {
        const self: TripManifestRec = this;
        return helper.consumerHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.consumer));
      }.bind(rec);

      (rec as any).getFullAddress = function () {
        const self: TripManifestRec = this;
        const addresses: Address[] =
          get(self, `${HelperService.REC_FIELD_MAP.consumer}.person.contact.addresses`) || [];
        const address: Address = (self.aid && addresses.find(a => a.id === self.aid)) || head<Address>(addresses);
        return address ? `${address.street}, ${address.city}, ${address.state}, ${address.zip}` : undefined;
      }.bind(rec);

      (rec as any).getMainPhone = function () {
        const self: TripManifestRec = this;
        const phone: Phone = head<Phone>(
          get(self, `${HelperService.REC_FIELD_MAP.consumer}.person.contact.phones`) || [],
        );
        return phone ? phone.value : undefined;
      }.bind(rec);

      (rec as any).getTranspInstrs = function () {
        const self: TripManifestRec = this;
        return get(self, `${HelperService.REC_FIELD_MAP.consumer}.transpInstrs`) || '';
      }.bind(rec);

      (rec as any).getSpecialInstrs = function () {
        const self: TripManifestRec = this;
        return get(self, `${HelperService.REC_FIELD_MAP.consumer}.specialInstrs`) || '';
      }.bind(rec);

      (rec as any).getNotes = function () {
        const self: TripManifestRec = this;
        const note: Note = head(get(self, `${HelperService.REC_FIELD_MAP.consumer}.relatedNotes`) || []);

        const info = note
          ? [
              [
                note.infoBy ? `by ${note.infoBy}` : '',
                note.infoDate ? `on ${utc(note.infoDate).format('YYYY/MM/DD')}` : '',
              ]
                .filter(chnk => !isEmpty(chnk))
                .join(' '),
              note.followUpDate ? `Follow up on ${utc(note.followUpDate).format('YYYY/MM/DD')}` : '',
            ]
              .filter(chnk => !isEmpty(chnk))
              .join(', ')
          : '';

        return note
          ? [
              moment(note.dateTime).format('YYYY/MM/DD HH:mm'),
              note.author,
              note.text + (!isEmpty(info) ? ` (${info})` : ''),
            ].join(' : ')
          : '';
      }.bind(rec);
    };

    const loadDetailsAsync = async (manifestId: number, recs: TripManifestRec[]) => {
      if (!manifestId || isEmpty(recs)) {
        return;
      }

      const {
        vehicles: vehicleMap,
        employees: employeeMap,
        escorts: escortMap,
        consumers: consumerMap,
      } = await this.api
        .loadDataDetails(
          manifestId,
          JSON.stringify(vFilter),
          JSON.stringify(eFilter),
          JSON.stringify(eFilter),
          JSON.stringify(cFilter),
        )
        .toPromise();
      employeeMap[this.employeeHelper.selfEntity.id] = this.employeeHelper.selfEntity;

      recs.forEach((rec: TripManifestRec) => prepareRec(rec, vehicleMap, employeeMap, escortMap, consumerMap));
    };

    const loadRecDetailsAsync = async (rec: TripManifestRec) => {
      const vehicleMap = {};
      const employeeMap = {};
      const escortMap = {};
      const consumerMap = {};
      if (rec.v > 0) {
        vehicleMap[rec.v] = await this.employeeHelper.vehicleApi
          .findById(rec.v, vFilter)
          .toPromise()
          .catch(() => null);
      }
      if (rec.e > 0) {
        employeeMap[rec.e] = await this.employeeHelper.api
          .findById(rec.e, eFilter)
          .toPromise()
          .catch(() => null);
      }
      if (rec.esc > 0) {
        escortMap[rec.esc] = await this.employeeHelper.api
          .findById(rec.esc, eFilter)
          .toPromise()
          .catch(() => null);
      }
      if (rec.c > 0) {
        consumerMap[rec.c] = await this.consumerHelper.api
          .findById(rec.c, cFilter)
          .toPromise()
          .catch(() => null);
      }
      employeeMap[this.employeeHelper.selfEntity.id] = this.employeeHelper.selfEntity;
      prepareRec(rec, vehicleMap, employeeMap, escortMap, consumerMap);
    };

    //

    await loadDetailsAsync(manifest.id, data);

    //

    const aso: ArrayStoreOptions = {
      key: TripManifestRec.getModelDefinition().idName,
      data,
    } as ArrayStoreOptions;

    const as: ArrayStore = new ArrayStore(aso);

    dxStoreInsertHooks(
      as,
      async (values: any) => {
        const recData = Object.keys(values)
          .filter(k => Object.keys(TripManifestRec.getModelDefinition().properties).includes(k))
          .reduce((o, k) => ({ ...o, [k]: values[k] }), {});
        const instance: TripManifestRec = await this.api.createRecords(manifest.id, recData).toPromise();
        await loadRecDetailsAsync(instance);
        return [instance];
      },
      async ([values], [resValues, resKey]) => {
        return [resValues, resKey];
      },
    );

    dxStoreUpdateHooks(
      as,
      async (key: any, values: any) => {
        const recData = Object.keys(values)
          .filter(k => Object.keys(TripManifestRec.getModelDefinition().properties).includes(k))
          .reduce((o, k) => ({ ...o, [k]: values[k] }), {});
        const instance: TripManifestRec = await this.api.updateByIdRecords(manifest.id, key, recData).toPromise();
        await loadRecDetailsAsync(instance);
        return [key, instance];
      },
      async ([key, values], [resValues, resKey]) => {
        return [resValues, resKey];
      },
    );

    dxStoreRemoveHooks(
      as,
      async (key: any) => {
        await this.api.destroyByIdRecords(manifest.id, key).toPromise();

        // await this.api.patchAttributes(manifest.id,
        //   {data: data.filter(rec => rec.id !== key)}).toPromise();

        return [key];
      },
      async ([key], [resKey]) => {
        return [resKey];
      },
    );

    dxStoreLoadHooks(
      as,
      async (obj: LoadOptions) => {
        const tripAutoSequence = this.config.get(ConfigProps.tripAutoSequence, false);
        if (tripAutoSequence) {
          this.calcSeats(data);
        }

        obj.sort = [
          ...(obj.sort || []),
          'getEmployeeFullName',
          'toResidence',
          HelperService.REC_FIELD_MAP.trip,
          HelperService.REC_FIELD_MAP.seat,
          // HelperService.REC_FIELD_MAP.time,
          // 'getConsumerFullName',
        ];
        return [obj];
      },
      async ([obj], [recs]) => {
        // await loadDetailsAsync(manifest.id, recs);
        return [recs];
      },
    );

    return as;
  }

  calcSeats(manifestData: TripManifestRec[]) {
    manifestData = manifestData || [];

    const byEmployee = groupBy(manifestData, HelperService.REC_FIELD_MAP.employeeId);
    toPairs(byEmployee).forEach(([, itemsByEmp]) => {
      const byDestination = groupBy(itemsByEmp, rec => rec[HelperService.REC_FIELD_MAP.destination] === 'RESIDENCE');
      toPairs(byDestination).forEach(([, itemsByDest]) => {
        const byTrip = groupBy(itemsByDest, HelperService.REC_FIELD_MAP.trip);
        toPairs(byTrip).forEach(([, itemsByTrip]) => {
          sortBy(itemsByTrip, [
            HelperService.REC_FIELD_MAP.time,
            HelperService.REC_FIELD_MAP._consumer_fullName,
          ]).forEach((rec, idx) => (rec.s = idx + 1));
        });
      });
    });
  }

  buildOrFilter(field: string, values: string[]): any[] {
    return (values || [])
      .map(v => [field, '=', v])
      .reduce((arr: any[], itm, idx) => {
        if (idx > 0) arr.push('or');
        arr.push(itm);
        return arr;
      }, []);
  }

  buildDriversFilter(field: string): any[] {
    return (this.getDriverPositionNames || [])
      .map(pn => [field, '=', pn])
      .reduce((arr: any, itm) => {
        if (arr && arr.length) {
          arr.push('or');
        }
        arr.push(itm);
        return arr;
      }, []);
  }

  async loadManifestOrTemplateAsync(date: Date): Promise<TripManifest> {
    if (!date) {
      return null;
    }

    const manifest: TripManifest = await this.api.getCurrentOrLastManifest(asShortDate(date)).toPromise();

    // fix DateString object
    if (isString(manifest.date) && manifest.date.indexOf('when') >= 0) {
      manifest.date = JSON.parse(manifest.date).when;
    }
    manifest.date = asShortDate(manifest.date);
    return manifest;
  }

  validateManifest(tenantId: number, manifest: TripManifest, tripsMap: any): any[] {
    if (!manifest.data) return [];
    const helper = this;
    const errors: ManifestValidationGroup[] = [];
    const invalidTrips: any[] = [];
    const notMatchAppointment: any[] = [];
    const notMatchConsumerName: any[] = [];
    const notMatchConsumerAddr: any[] = [];
    const pendingConsumer: any[] = [];
    const noAccessEmployees: any[] = [];

    manifest.data.forEach(d => {
      const trip = tripsMap[d[HelperService.REC_FIELD_MAP.tripId]];
      if (trip) {
        if (d.at && trip._time !== d.at.replace(':', ''))
          notMatchAppointment.push({
            ...d,
            validationError: `From Broker: ${`${trip._time.slice(0, 2)}:${trip._time.slice(-2)}`}<br> On Manifest: ${
              d.at
            }`,
          });
        const consumerFullName = helper.consumerHelper.displayExpr({
          person: { firstname: trip._firstname, lastname: trip._lastname },
        } as any);
        const [a1, a2] = [consumerFullName, d.getConsumerFullName()].map(a => a.toLowerCase().replace(/[,\s]+/g, ''));
        if (a1 != a2 && [trip._firstname, trip._lastname].find(n => !a2.includes(n.toLowerCase())))
          notMatchConsumerName.push({
            ...d,
            validationError: `From Broker: ${consumerFullName}<br> On Manifest: ${d.getConsumerFullName()}`,
          });
        if (trip._broker === 'MTM') {
          if (trip['Trip Status'] !== 'S1')
            invalidTrips.push({ ...d, validationError: `Trip Status: ${trip['Trip Status']}` });
          const type = trip['Trip Type'] == 'T' ? 'Pickup' : 'Delivery';
          const consumerAddr = `${trip[`${type} Address`]}, ${trip[`${type} City`]}, ${trip[`${type} State`]}, ${
            trip[`${type} Zip Code`]
          }`;
          const [a1, a2] = [d.getFullAddress(), consumerAddr].map(a => a.toLowerCase().replace(/[,\s]+/g, ''));
          const [[b1], [b2]] = [d.getFullAddress(), consumerAddr].map(a => a.split(/[,\s]/g));
          const [c1, c2] = [d.getFullAddress(), consumerAddr].map(a => a.split(/[,\s]/g).pop());
          if (a1 != a2 && (b1 != b2 || c1 != c2 || StringSimilarity.compareTwoStrings(a1, a2) < 0.55))
            notMatchConsumerAddr.push({
              ...d,
              validationError: `From Broker: ${consumerAddr}<br> On Manifest: ${d.getFullAddress()}`,
            });
        }
      }

      if (d.__consumer && d.__consumer.status == 'PENDING')
        pendingConsumer.push({
          ...d,
          validationError: `Consumer Status: ${d.__consumer.status}`,
        });
      if (d.__employee && d.__employee.id !== -1 && !(d.__employee.tenantIds || []).includes(tenantId))
        noAccessEmployees.push({
          ...d,
          validationError: `Driver does not have access to current tenant`,
        });
    });

    if (invalidTrips.length)
      errors.push({
        code: 'INVALID_TRIP_ID',
        error: 'There are invalid Trip IDs',
        recHint: '· Invalid Trip ID',
        recs: invalidTrips,
        priority: 'danger-crossed',
      });

    if (notMatchAppointment.length)
      errors.push({
        code: 'NOT_MATCH_APPOINTMENT',
        error: 'There are mismatch appointments',
        recHint: '· Appointment Mismatch',
        recs: notMatchAppointment,
        priority: 'danger',
      });

    if (notMatchConsumerName.length)
      errors.push({
        code: 'NOT_MATCH_CONSUMER',
        error: "There are mismatch consumers' names",
        recHint: "· Consumer's Name Mismatch",
        recs: notMatchConsumerName,
        priority: 'warning',
      });

    if (notMatchConsumerAddr.length)
      errors.push({
        code: 'NOT_MATCH_ADDR_CONSUMER',
        error: "There are mismatch consumers' addresses",
        recHint: "· Consumer's Address Mismatch",
        recs: notMatchConsumerAddr,
        priority: 'warning',
      });

    if (pendingConsumer.length)
      errors.push({
        code: 'PENDING_CONSUMER',
        error: 'There are pending consumers',
        recHint: '· Pending Consumer',
        recs: pendingConsumer,
        priority: 'warning',
      });
    if (noAccessEmployees.length)
      errors.push({
        code: 'NO_ACCESS_EMPLOYEES',
        error: `There are drivers without access to current tenant`,
        recHint: '· Driver does not have access to current tenant',
        recs: noAccessEmployees,
        priority: 'warning',
      });

    return errors;
  }
  validateManifest$(manifest: TripManifest): Observable<any[]> {
    return manifest && manifest.id ? this.api.validateManifest(manifest.id) : of([]);
  }

  buildConsumerPerDriverMap(recs: TripManifestRec[]): Map<number, Set<number>> {
    const pairs: Array<[number, Set<number>]> = query(recs || [])
      .filter(rec => rec.e && rec.e > 0)
      .groupBy(HelperService.REC_FIELD_MAP.employeeId)
      .toArray()
      .map(({ key, items }): [number, Set<number>] => [
        key,
        new Set<number>(items.map((rec: TripManifestRec) => rec.c)),
      ]);

    return new Map<number, Set<number>>(pairs);
  }

  buildConsumerTotalSet(recs: TripManifestRec[]): Set<number> {
    return new Set<number>((recs || []).filter(rec => rec.c && rec.c > 0).map(rec => rec.c));
  }

  buildVehicleTotalSet(recs: TripManifestRec[]): Set<number> {
    return new Set<number>((recs || []).filter(rec => rec.v && rec.v > 0).map(rec => rec.v));
  }

  buildDriverTotalSet(recs: TripManifestRec[]): Set<number> {
    return new Set<number>((recs || []).filter(rec => rec.e && rec.e > 0).map(rec => rec.e));
  }

  getManifestConsumerTripsMap(recs: TripManifestRec[]): Map<number, Map<string, Set<string>>> {
    return new Map(
      toPairs(groupBy(recs || [], HelperService.REC_FIELD_MAP.consumerId)).map(
        ([consumerId, subRecs]): [number, Map<string, Set<string>>] => [
          parseInt(consumerId, 10),
          new Map<string, Set<string>>(
            toPairs(groupBy(subRecs, HelperService.REC_FIELD_MAP.destination)).map(
              ([dest, subSubRecs]): [string, Set<string>] => [dest, new Set<string>(subSubRecs.map(rec => rec.id))],
            ),
          ),
        ],
      ),
    );
  }

  async buildDateManifestMapAsync(weeksBefore: number = 5, weeksAfter: number = 1): Promise<Map<string, number>> {
    const from: string = asShortDate(moment().subtract(weeksBefore, 'weeks'));
    const to: string = asShortDate(moment().add(weeksAfter, 'weeks'));

    const manifests: TripManifest[] =
      (await this.api
        .find<TripManifest>({
          where: {
            and: [{ date: { gt: from } }, { date: { lte: to } }],
          },
          fields: ['date', 'id'],
        })
        .toPromise()) || [];

    const pairs = manifests.map((m): [string, number] => [asShortDate(m.date), m.id]);
    return new Map<string, number>(pairs);
  }

  exportManifestFileName(employee: Employee | EmployeeView = null): string {
    const timestamp = moment().format('YYYY.MM.DD');
    const firstName = get(employee, 'person_firstname', get(employee, 'person.firstname', ''));
    const lastName = get(employee, 'person_lastname', get(employee, 'person.lastname', ''));
    return `${employee ? `${firstName}_${lastName}` : 'all_drivers'}__${timestamp}`;
  }

  getWarningGroupsWithRecs(validationSummary: ManifestValidationGroup[], cellInfo, codes: string[]): any[] {
    return (validationSummary || [])
      .filter(group => codes.includes(group.code))
      .map(group => ({ ...group, rec: group.recs.find(rec => rec.id === cellInfo.data.id) }))
      .filter(warning => warning.rec);
  }

  processCellWarnings(validationSummary: ManifestValidationGroup[], cellInfo, codes: string[], noHint = false): void {
    if (cellInfo.rowType === 'data' && cellInfo.data && cellInfo.data.id) {
      const groups = this.getWarningGroupsWithRecs(validationSummary, cellInfo, codes);
      if (groups && groups.length) {
        const [hint, priorities] = groups.reduce(
          ([str, prs], g) => [
            str.concat((str.length ? '\n' : '') + g.recHint),
            g.priority ? prs.concat(g.priority) : prs,
          ],
          ['', []],
        );
        (cellInfo.cellElement as HTMLElement).classList.add(
          priorities.find(p => p.includes('danger')) ? 'cell-danger' : 'cell-yellow',
        );
        if (priorities.find(p => p.includes('crossed')))
          (cellInfo.cellElement as HTMLElement).setAttribute('style', 'text-decoration: line-through');
        if (!noHint) (cellInfo.cellElement as HTMLElement).setAttribute('title', hint);
      }
    }
  }

  async printManifestAsync(manifest: TripManifest, settings: any) {
    settings = { ...settings, baseUrl: environment.apiBaseUrl };
    const jobID = await this.api.pdfManifestJob(manifest.id, JSON.stringify(settings)).toPromise();

    const { bucket, filePdf, fileHtml, uri } = await this.pusher.requestResponse(jobID).toPromise();
    const resp = await this.http
      .get(uri, {
        responseType: 'blob',
        withCredentials: false,
      })
      .toPromise();

    const src = URL.createObjectURL(resp);
    // console.log(resp, src);

    await this.common.printHtmlSrc(document, src).toPromise();
  }

  async getXlsxManifestAsync(manifest: TripManifest, settings: any) {
    settings = { ...settings, baseUrl: environment.apiBaseUrl };
    const jobID = await this.api.xlsxManifestJob(manifest.id, JSON.stringify(settings)).toPromise();

    const { bucket, file, url } = await this.pusher.requestResponse(jobID).toPromise();
    // const url = `https://storage.googleapis.com/${bucket}/${file}`;

    window.open(url);
  }

  async tripsFromBrokerToManifestJob(manifest: TripManifest) {
    const jobID = await this.api.fillUpBrokerDataJob(manifest.id).toPromise();
    const { id } = await this.pusher.requestResponse(jobID).toPromise();
    return id;
  }

  async getDestinationsDsAsync(currentTenantId: number) {
    let ds: any[] = [];

    if (currentTenantId) {
      const currentTenant: Facility = await this.dss.getApi(Facility).findById<Facility>(currentTenantId).toPromise();

      const destList = ((await this.dss.getApi<ConfigApi>(Config).getAllDestinations().toPromise()) as any[]).map(
        dest => ({ ID: dest.short, Name: dest.name, Group: dest.group }),
      );

      // TODO: remove harcode
      if (currentTenant.type === 'BASE') {
        ds = DESTINATIONS_FOR_BASE;
      } else {
        ds = DESTINATIONS;
      }

      ds = uniqBy(ds.concat(destList), 'ID');
    }

    return ds;
  }
}

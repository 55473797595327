import {
  Address
} from '../index';

declare var Object: any;
export interface ConsumerViewInterface {
  "id": number;
  "tenantId": number;
  "personId": number;
  "programId"?: number;
  "status": string;
  "internalID"?: string;
  "facilityID"?: string;
  "mci"?: string;
  "autoDispatch"?: number;
  "source"?: string;
  "defaultDestination"?: string;
  "specialInstrs"?: string;
  "transpInstrs"?: string;
  "transpCodes": any;
  "signImgFileId"?: string;
  "profileImgFileId"?: string;
  "notes"?: string;
  "daysNotes"?: string;
  "brokerNotes"?: string;
  "data": any;
  "activeMco"?: string;
  "activeBroker"?: string;
  "clientSince"?: any;
  "repetitiveMode"?: string;
  "weekDays": any;
  "mealMeta": any;
  "preferredEmployees"?: any;
  "avoidEmployees"?: any;
  "eligibility": any;
  "lastEligibility"?: string;
  "lastEligibilityDate"?: any;
  "keepStretcher"?: number;
  "onBoardingDuration"?: number;
  "offBoardingDuration"?: number;
  "c10nAgency"?: string;
  "cwName"?: string;
  "cwPhone"?: string;
  "cwFax"?: string;
  "cwEmail"?: string;
  "cwNote"?: string;
  "legacyCustomerID"?: string;
  "tcInformedConsentDocumentSignatureDate"?: any;
  "tcInformedConsentFile"?: string;
  "persLastSuccessfulTest"?: Date;
  "persAlertsLast30D"?: number;
  "persAlertsLast90D"?: number;
  "persAlertsLast12M"?: number;
  "persNotes"?: string;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "facility_id"?: number;
  "facility_type"?: string;
  "facility_shortname"?: string;
  "facility_legalName"?: string;
  "program_id"?: number;
  "program_name"?: string;
  "person_id"?: number;
  "person_firstname"?: string;
  "person_lastname"?: string;
  "person_middlename"?: string;
  "person_nickname"?: string;
  "person_sex"?: string;
  "person_dob"?: any;
  "person_notes"?: string;
  "person_data"?: any;
  "person_contactId"?: number;
  "person_contact_id"?: number;
  "lastNote_id"?: number;
  "lastNote_dateTime"?: Date;
  "lastNote_author"?: string;
  "lastNote_text"?: string;
  "lastNote_infoBy"?: string;
  "lastNote_infoDate"?: any;
  "lastNote_followUpDate"?: any;
  "lastNote_source"?: string;
  "lastNote_meta"?: any;
  "lastNote_userId"?: number;
  "lastNote_user_username"?: string;
  "lastNote"?: string;
  addresses?: Address[];
}

export class ConsumerView implements ConsumerViewInterface {
  "id": number;
  "tenantId": number;
  "personId": number;
  "programId": number;
  "status": string;
  "internalID": string;
  "facilityID": string;
  "mci": string;
  "autoDispatch": number;
  "source": string;
  "defaultDestination": string;
  "specialInstrs": string;
  "transpInstrs": string;
  "transpCodes": any;
  "signImgFileId": string;
  "profileImgFileId": string;
  "notes": string;
  "daysNotes": string;
  "brokerNotes": string;
  "data": any;
  "activeMco": string;
  "activeBroker": string;
  "clientSince": any;
  "repetitiveMode": string;
  "weekDays": any;
  "mealMeta": any;
  "preferredEmployees": any;
  "avoidEmployees": any;
  "eligibility": any;
  "lastEligibility": string;
  "lastEligibilityDate": any;
  "keepStretcher": number;
  "onBoardingDuration": number;
  "offBoardingDuration": number;
  "c10nAgency": string;
  "cwName": string;
  "cwPhone": string;
  "cwFax": string;
  "cwEmail": string;
  "cwNote": string;
  "legacyCustomerID": string;
  "tcInformedConsentDocumentSignatureDate": any;
  "tcInformedConsentFile": string;
  "persLastSuccessfulTest": Date;
  "persAlertsLast30D": number;
  "persAlertsLast90D": number;
  "persAlertsLast12M": number;
  "persNotes": string;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "facility_id": number;
  "facility_type": string;
  "facility_shortname": string;
  "facility_legalName": string;
  "program_id": number;
  "program_name": string;
  "person_id": number;
  "person_firstname": string;
  "person_lastname": string;
  "person_middlename": string;
  "person_nickname": string;
  "person_sex": string;
  "person_dob": any;
  "person_notes": string;
  "person_data": any;
  "person_contactId": number;
  "person_contact_id": number;
  "lastNote_id": number;
  "lastNote_dateTime": Date;
  "lastNote_author": string;
  "lastNote_text": string;
  "lastNote_infoBy": string;
  "lastNote_infoDate": any;
  "lastNote_followUpDate": any;
  "lastNote_source": string;
  "lastNote_meta": any;
  "lastNote_userId": number;
  "lastNote_user_username": string;
  "lastNote": string;
  addresses: Address[];
  constructor(data?: ConsumerViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ConsumerView`.
   */
  public static getModelName() {
    return "ConsumerView";
  }

  /**
  * This method creates an instance of ConsumerView for dynamic purposes.
  **/
  public static factory(data: ConsumerViewInterface): ConsumerView{
    return new ConsumerView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ConsumerView',
      plural: 'ConsumerViews',
      path: 'ConsumerViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "programId": {
          name: 'programId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "internalID": {
          name: 'internalID',
          type: 'string'
        },
        "facilityID": {
          name: 'facilityID',
          type: 'string'
        },
        "mci": {
          name: 'mci',
          type: 'string'
        },
        "autoDispatch": {
          name: 'autoDispatch',
          type: 'number'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "defaultDestination": {
          name: 'defaultDestination',
          type: 'string'
        },
        "specialInstrs": {
          name: 'specialInstrs',
          type: 'string'
        },
        "transpInstrs": {
          name: 'transpInstrs',
          type: 'string'
        },
        "transpCodes": {
          name: 'transpCodes',
          type: 'any'
        },
        "signImgFileId": {
          name: 'signImgFileId',
          type: 'string'
        },
        "profileImgFileId": {
          name: 'profileImgFileId',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "daysNotes": {
          name: 'daysNotes',
          type: 'string'
        },
        "brokerNotes": {
          name: 'brokerNotes',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "activeMco": {
          name: 'activeMco',
          type: 'string'
        },
        "activeBroker": {
          name: 'activeBroker',
          type: 'string'
        },
        "clientSince": {
          name: 'clientSince',
          type: 'any'
        },
        "repetitiveMode": {
          name: 'repetitiveMode',
          type: 'string'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'any'
        },
        "mealMeta": {
          name: 'mealMeta',
          type: 'any'
        },
        "preferredEmployees": {
          name: 'preferredEmployees',
          type: 'any'
        },
        "avoidEmployees": {
          name: 'avoidEmployees',
          type: 'any'
        },
        "eligibility": {
          name: 'eligibility',
          type: 'any'
        },
        "lastEligibility": {
          name: 'lastEligibility',
          type: 'string'
        },
        "lastEligibilityDate": {
          name: 'lastEligibilityDate',
          type: 'any'
        },
        "keepStretcher": {
          name: 'keepStretcher',
          type: 'number'
        },
        "onBoardingDuration": {
          name: 'onBoardingDuration',
          type: 'number'
        },
        "offBoardingDuration": {
          name: 'offBoardingDuration',
          type: 'number'
        },
        "c10nAgency": {
          name: 'c10nAgency',
          type: 'string'
        },
        "cwName": {
          name: 'cwName',
          type: 'string'
        },
        "cwPhone": {
          name: 'cwPhone',
          type: 'string'
        },
        "cwFax": {
          name: 'cwFax',
          type: 'string'
        },
        "cwEmail": {
          name: 'cwEmail',
          type: 'string'
        },
        "cwNote": {
          name: 'cwNote',
          type: 'string'
        },
        "legacyCustomerID": {
          name: 'legacyCustomerID',
          type: 'string'
        },
        "tcInformedConsentDocumentSignatureDate": {
          name: 'tcInformedConsentDocumentSignatureDate',
          type: 'any'
        },
        "tcInformedConsentFile": {
          name: 'tcInformedConsentFile',
          type: 'string'
        },
        "persLastSuccessfulTest": {
          name: 'persLastSuccessfulTest',
          type: 'Date'
        },
        "persAlertsLast30D": {
          name: 'persAlertsLast30D',
          type: 'number'
        },
        "persAlertsLast90D": {
          name: 'persAlertsLast90D',
          type: 'number'
        },
        "persAlertsLast12M": {
          name: 'persAlertsLast12M',
          type: 'number'
        },
        "persNotes": {
          name: 'persNotes',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "facility_id": {
          name: 'facility_id',
          type: 'number'
        },
        "facility_type": {
          name: 'facility_type',
          type: 'string'
        },
        "facility_shortname": {
          name: 'facility_shortname',
          type: 'string'
        },
        "facility_legalName": {
          name: 'facility_legalName',
          type: 'string'
        },
        "program_id": {
          name: 'program_id',
          type: 'number'
        },
        "program_name": {
          name: 'program_name',
          type: 'string'
        },
        "person_id": {
          name: 'person_id',
          type: 'number'
        },
        "person_firstname": {
          name: 'person_firstname',
          type: 'string'
        },
        "person_lastname": {
          name: 'person_lastname',
          type: 'string'
        },
        "person_middlename": {
          name: 'person_middlename',
          type: 'string'
        },
        "person_nickname": {
          name: 'person_nickname',
          type: 'string'
        },
        "person_sex": {
          name: 'person_sex',
          type: 'string'
        },
        "person_dob": {
          name: 'person_dob',
          type: 'any'
        },
        "person_notes": {
          name: 'person_notes',
          type: 'string'
        },
        "person_data": {
          name: 'person_data',
          type: 'any'
        },
        "person_contactId": {
          name: 'person_contactId',
          type: 'number'
        },
        "person_contact_id": {
          name: 'person_contact_id',
          type: 'number'
        },
        "lastNote_id": {
          name: 'lastNote_id',
          type: 'number'
        },
        "lastNote_dateTime": {
          name: 'lastNote_dateTime',
          type: 'Date'
        },
        "lastNote_author": {
          name: 'lastNote_author',
          type: 'string'
        },
        "lastNote_text": {
          name: 'lastNote_text',
          type: 'string'
        },
        "lastNote_infoBy": {
          name: 'lastNote_infoBy',
          type: 'string'
        },
        "lastNote_infoDate": {
          name: 'lastNote_infoDate',
          type: 'any'
        },
        "lastNote_followUpDate": {
          name: 'lastNote_followUpDate',
          type: 'any'
        },
        "lastNote_source": {
          name: 'lastNote_source',
          type: 'string'
        },
        "lastNote_meta": {
          name: 'lastNote_meta',
          type: 'any'
        },
        "lastNote_userId": {
          name: 'lastNote_userId',
          type: 'number'
        },
        "lastNote_user_username": {
          name: 'lastNote_user_username',
          type: 'string'
        },
        "lastNote": {
          name: 'lastNote',
          type: 'string'
        },
      },
      relations: {
        addresses: {
          name: 'addresses',
          type: 'Address[]',
          model: 'Address',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
      }
    }
  }
}

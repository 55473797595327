import {
  Employee
} from '../index';

declare var Object: any;
export interface MyUserViewInterface {
  "id": number;
  "email": string;
  "username"?: string;
  "realm"?: string;
  "emailVerified"?: number;
  "status": string;
  "data": any;
  "mfaEmailEnabled"?: number;
  "mfaSmsEnabled"?: number;
  "lastLoginTime"?: Date;
  "lastOnlineTime"?: Date;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "employee_id"?: number;
  "employee_tenantIds"?: any;
  "person_id"?: number;
  "person_firstname"?: string;
  "person_lastname"?: string;
  "person_middlename"?: string;
  "_roleIds"?: any;
  "_roles"?: any;
  "_r_name_map"?: any;
  "_facilityIds"?: any;
  "_facilities"?: any;
  "_f_short_map"?: any;
  employee?: Employee;
}

export class MyUserView implements MyUserViewInterface {
  "id": number;
  "email": string;
  "username": string;
  "realm": string;
  "emailVerified": number;
  "status": string;
  "data": any;
  "mfaEmailEnabled": number;
  "mfaSmsEnabled": number;
  "lastLoginTime": Date;
  "lastOnlineTime": Date;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "employee_id": number;
  "employee_tenantIds": any;
  "person_id": number;
  "person_firstname": string;
  "person_lastname": string;
  "person_middlename": string;
  "_roleIds": any;
  "_roles": any;
  "_r_name_map": any;
  "_facilityIds": any;
  "_facilities": any;
  "_f_short_map": any;
  employee: Employee;
  constructor(data?: MyUserViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyUserView`.
   */
  public static getModelName() {
    return "MyUserView";
  }

  /**
  * This method creates an instance of MyUserView for dynamic purposes.
  **/
  public static factory(data: MyUserViewInterface): MyUserView{
    return new MyUserView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyUserView',
      plural: 'MyUserViews',
      path: 'MyUserViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "mfaEmailEnabled": {
          name: 'mfaEmailEnabled',
          type: 'number'
        },
        "mfaSmsEnabled": {
          name: 'mfaSmsEnabled',
          type: 'number'
        },
        "lastLoginTime": {
          name: 'lastLoginTime',
          type: 'Date'
        },
        "lastOnlineTime": {
          name: 'lastOnlineTime',
          type: 'Date'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "employee_id": {
          name: 'employee_id',
          type: 'number'
        },
        "employee_tenantIds": {
          name: 'employee_tenantIds',
          type: 'any'
        },
        "person_id": {
          name: 'person_id',
          type: 'number'
        },
        "person_firstname": {
          name: 'person_firstname',
          type: 'string'
        },
        "person_lastname": {
          name: 'person_lastname',
          type: 'string'
        },
        "person_middlename": {
          name: 'person_middlename',
          type: 'string'
        },
        "_roleIds": {
          name: '_roleIds',
          type: 'any'
        },
        "_roles": {
          name: '_roles',
          type: 'any'
        },
        "_r_name_map": {
          name: '_r_name_map',
          type: 'any'
        },
        "_facilityIds": {
          name: '_facilityIds',
          type: 'any'
        },
        "_facilities": {
          name: '_facilities',
          type: 'any'
        },
        "_f_short_map": {
          name: '_f_short_map',
          type: 'any'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
      }
    }
  }
}

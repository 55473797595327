import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import notify from 'devextreme/ui/notify';
import { PivotGridDataSourceField, PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import identity from 'lodash-es/identity';
import isNil from 'lodash-es/isNil';
import moment, { utc } from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { LoggerService, MyUser, MyUserApi } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DlgImportCardComponent } from '../../dialogs/dlg-import-card/dlg-import-card.component';

@Component({
  selector: 'app-fuel-card-txs-grid',
  templateUrl: './fuel-card-txs-grid.component.html',
  styleUrls: ['./fuel-card-txs-grid.component.scss'],
})
export class FuelCardTxsGridComponent extends ABaseComponent implements OnInit {
  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      {
        caption: 'Year',
        dataField: 'Transaction Date',
        dataType: 'date',
        groupInterval: 'year',
        displayFolder: 'date',
      },
      {
        caption: 'Month',
        dataField: 'Transaction Date',
        dataType: 'date',
        groupInterval: 'month',
        displayFolder: 'date',
      },
      {
        caption: 'Day of Week',
        dataField: 'Transaction Date',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        area: 'column',
        dataField: 'Transaction Date',
        dataType: 'date',
        groupInterval: 'day',
        displayFolder: 'date',
        selector: data => {
          const dateMoment = utc(data['Transaction Date']);
          return [dateMoment.format('MM/DD/YYYY'), dateMoment.format('dd')].join('-');
        },
      },

      {
        caption: 'Driver First Name',
        area: 'row',
        dataField: 'Driver First Name',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Driver Last Name',
        area: 'row',
        dataField: 'Driver Last Name',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },

      {
        caption: 'Card Name',
        area: 'row',
        dataField: '_cardName',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Card Number',
        area: 'row',
        dataField: '_cardNumber',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },

      {
        caption: 'Total Fuel Cost',
        area: 'data',
        dataField: 'Total Fuel Cost',
        dataType: 'number',
        format: {
          type: 'currency',
          precision: 2,
        },
        summaryType: 'sum',
        isMeasure: true,
      },
      {
        caption: 'Distance Driven',
        dataField: 'Distance Driven',
        dataType: 'number',
        format: {
          useGrouping: true,
        } as any,
        summaryType: 'sum',
        isMeasure: true,
      },
      {
        caption: 'Units',
        dataField: 'Units',
        dataType: 'number',
        format: {
          useGrouping: true,
        } as any,
        summaryType: 'sum',
        isMeasure: true,
      },
      { dataField: '_frId', visible: false },
      // {
      //   caption: 'Has Receipt',
      //   dataField: '_frId',
      //   dataType: 'number',
      //   // customizeText: (cellInfo) => {
      //   //   return isEmpty(cellInfo.value) ? 'N' : 'Y';
      //   // },
      //   // summaryType: 'custom',
      //   // calculateCustomSummary: (options) => {
      //   //   console.log(options);
      //   //   switch (options.summaryProcess) {
      //   //     case 'start':
      //   //       options.totalValue = new Set<string>();
      //   //       break;
      //   //     case 'calculate':
      //   //       // console.log(options.value);
      //   //       (options.totalValue as Set<string>).add(options.value);
      //   //       break;
      //   //     case 'finalize':
      //   //       options.totalValue = (options.totalValue as Set<string>).has('N') ? '-N-' : '-Y-';
      //   //       break;
      //   //   }
      //   // },
      //   isMeasure: true,
      // },
    ];
  }

  gridDso: DataSourceOptions;
  pivotDso: PivotGridDataSourceOptions;
  userDso$: any;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  selectedTabIndex = 0;
  tabs: any[] = [
    {
      title: 'Grid',
      template: 'grid',
    },
    {
      title: 'Pivot',
      template: 'pivot',
    },
  ];

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  grid_stateStoring: any;
  pivot_stateStoring: any;
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild(DxPivotGridComponent, { static: false }) pivot: DxPivotGridComponent;

  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public employeeHelper: EmployeeHelperService,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '411e8d71-4336-4134-a5fe-241afe67e5da',
    };
    this.pivot_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'd45fee79-e350-41a0-a7e3-83563c593396',
    };

    this.gridDso = this.buildGridDataSource();
    this.pivotDso = this.buildPivotDataSource();
    this.userDso$ = this.buildUserDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(identity),
        tap(() => this.grid.instance.beginCustomLoading('...')),
        tap(() => {
          this.pivot.instance.getDataSource().reload();
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'importCardTxs',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-file-export',
        text: 'Import Cards Transactions',
        hint: 'Import Fuel Cards Transactions',
        onClick: this.grid_toolbar_importCardTxs_onClick.bind(this),
      },
    });
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      // console.log(e);
      if (isNil(oc(e).data._frId())) {
        (e.cellElement as HTMLElement).style.color = 'indianred';
      }
    }
  }

  private grid_toolbar_importCardTxs_onClick(e) {
    this.dialog
      .open(DlgImportCardComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: { jobName: 'IMPORT_WAWA_CARD_TXS' },
      })
      .afterClosed()
      .pipe(
        tap(res => {
          if (res) oc(this.grid).instance.refresh()();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildFilter() {
    const from = this.selectedFromValue;
    const to = this.selectedToValue;

    const fromMoment = from && moment(from);
    const toMoment = to && moment(to).add(1, 'days');

    const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
    const strTo = toMoment && toMoment.format('YYYY-MM-DD');

    const fltr = {
      fromInc: strFrom,
      toExcl: strTo,
    };

    return [
      ...(fltr.fromInc ? [['Transaction Date', '>=', utc(fltr.fromInc).utc().toDate()]] : []),
      ...(fltr.toExcl ? [['Transaction Date', '<', utc(fltr.toExcl).utc().toDate()]] : []),
    ];
  }

  private buildGridDataSource() {
    const self = this;
    const col = 'WawaFuelCardTransactions';
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const aggregate = [
          // {
          //   $addFields: {_ctime: {$toDate: '$_id'}},
          // }
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      sort: [{ selector: '_transactionDateTime', desc: true }],
      postProcess(data) {
        data.forEach(rec => {
          rec.getPostedDate = function () {
            return this['Posted Date'] || this['Post Date'];
          }.bind(rec);
        });

        return data;
      },
    };
    return dso;
  }

  private buildPivotDataSource() {
    const self = this;
    const col = 'WawaFuelCardTransactions';
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const filterValue = this.buildFilter();
        if (filterValue.length) {
          loadOptions.filter = [filterValue, 'and', loadOptions.filter];
        }

        loadOptions.select = {
          ...loadOptions.select,
          fields: {
            'Transaction Date': true,
            'Driver First Name': true,
            'Driver Last Name': true,
            'Total Fuel Cost': true,
            'Distance Driven': true,
            Units: true,
            _cardName: true,
            _cardNumber: true,
            _frId: true,
          },
        };

        const aggregate = [
          // {
          //   $addFields: {_ctime: {$toDate: '$_id'}},
          // }
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });

    const dso: PivotGridDataSourceOptions = {
      store,
      fields: this.pivotFields,
    };
    return dso;
  }

  private buildUserDataSource() {
    return this.dss
      .getApi<MyUserApi>(MyUser)
      .getUsernames()
      .pipe(map(arr => new ArrayStore(arr)));
  }

  filterDs() {
    this.$filterEvent$.next(true);
  }

  pivot_onCellPrepared(e) {
    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'D') {
      // console.log(e);
      // console.log(this.pivot.instance.getDataSource().getData());

      const value = e.cell.value;
      // if (!isNaN(value) && value > 0) {
      //   // e.cellElement.style.backgroundColor = 'lightGreen';
      // } else if (value === 0) {
      // }
    }
  }
}

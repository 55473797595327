<dx-validation-group [formGroup]="form">
  <ng-container *ngIf="selectedTab === 'General' || !selectedTab">
    <dx-responsive-box width="790px">
      <dxi-row [ratio]="1" [shrink]="1" [baseSize]="'auto'"></dxi-row>
      <dxi-col [ratio]="1" [shrink]="0" [baseSize]="'50%'"></dxi-col>
      <dxi-col [ratio]="0" [shrink]="0" [baseSize]="'0px'"></dxi-col>
      <dxi-col [ratio]="1" [shrink]="0" [baseSize]="'50%'"></dxi-col>

      <dxi-item>
        <dxi-location [row]="0" [col]="0"></dxi-location>

        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Personal Info</div>

          <div style="width: 80%">
            <ng-container formGroupName="person">
              <div class="dx-field">
                <label class="dx-field-label">First Name *</label>
                <div class="dx-field-value">
                  <dx-text-box formControlName="firstname">
                    <dx-validator name="First Name">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <label class="dx-field-label">Last Name *</label>
                <div class="dx-field-value">
                  <dx-text-box formControlName="lastname">
                    <dx-validator name="Last Name">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <label class="dx-field-label">Middle Name</label>
                <div class="dx-field-value">
                  <dx-text-box formControlName="middlename"></dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <label class="dx-field-label">DOB</label>
                <label style="width: 18%; float: right; margin: 5px 0; text-align: right" *ngIf="age"
                >Age:&nbsp;{{ age }}</label
                >
                <div class="dx-field-value" [ngStyle]="{ width: age ? '42%' : '60%' }">
                  <dx-date-box formControlName="dob" applyValueMode="useButtons"></dx-date-box>
                </div>
              </div>

              <div class="dx-field">
                <label class="dx-field-label">Sex</label>
                <div class="dx-field-value">
                  <dx-select-box
                    [dataSource]="personSexesDS"
                    displayExpr="Name"
                    valueExpr="ID"
                    formControlName="sex"></dx-select-box>
                </div>
              </div>
            </ng-container>

            <div class="dx-field">
              <label class="dx-field-label">Client Since</label>
              <div class="dx-field-value">
                <dx-date-box formControlName="clientSince" applyValueMode="useButtons"></dx-date-box>
              </div>
            </div>

            <ng-container formGroupName="data">
              <div class="dx-field">
                <div class="dx-field-value">
                  <div>
                    <dx-check-box formControlName="unableToSign" text="Client Unable To Sign"></dx-check-box>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container formGroupName="person">
            <!--<mat-divider></mat-divider>-->

            <ng-container formGroupName="contact">
              <!--<mat-divider></mat-divider>-->

              <!--<div class="dx-fieldset-header">Contact Info</div>

              <div class="dx-field">
                <div class="dx-field-label">Notes</div>
                <div class="dx-field-value">
                  <dx-text-area formControlName="notes"></dx-text-area>
                </div>
              </div>-->

              <ng-container formArrayName="addresses">
                <br />

                <app-form-array-group
                  entityName="place"
                  icon="place"
                  [compact]="true"
                  [formArray]="addressesFormArray"
                  (addItem)="addFormArrayItem('person.contact.addresses')"
                  (removeItem)="removeFormArrayItem('person.contact.addresses', $event)"></app-form-array-group>
              </ng-container>

              <!--<mat-divider></mat-divider>-->

              <ng-container formArrayName="phones">
                <br />

                <app-form-array-group
                  entityName="phone"
                  icon="phone"
                  [formArray]="phonesFormArray"
                  (addItem)="addFormArrayItem('person.contact.phones')"
                  (removeItem)="removeFormArrayItem('person.contact.phones', $event)"></app-form-array-group>
              </ng-container>

              <!--<mat-divider></mat-divider>-->

              <ng-container formArrayName="emails">
                <br />

                <app-form-array-group
                  entityName="email"
                  icon="email"
                  [formArray]="emailsFormArray"
                  (addItem)="addFormArrayItem('person.contact.emails')"
                  (removeItem)="removeFormArrayItem('person.contact.emails', $event)"></app-form-array-group>
              </ng-container>
            </ng-container>

            <div class="dx-field">
              <label class="_dx-field-label"
              >Client Notes
                <ng-container
                  *ngTemplateOutlet="
                    infoIcon;
                    context: { $implicit: tooltips.clientNotes, idPrefix: '7239e9e0-49f4-4d79-bbb6-9d2c8ec32759' }
                  "></ng-container>
              </label>
              <div class="_dx-field-value">
                <dx-text-area formControlName="notes"></dx-text-area>
              </div>
            </div>

            <ng-container formGroupName="data">
              <div class="dx-field">
                <label class="dx-field-label">Native Language</label>
                <div class="dx-field-value">
                  <dx-select-box [dataSource]="consumerLangs" formControlName="nativeLang"></dx-select-box>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="dx-field">
            <label class="_dx-field-label"
            >Broker Notes
              <ng-container
                *ngTemplateOutlet="
                  infoIcon;
                  context: { $implicit: tooltips.brokerNotes, idPrefix: '9007b79b-eb53-4037-a66c-abfd162ff4e0' }
                "></ng-container>
            </label>
            <div class="_dx-field-static">
              <dx-text-area [readOnly]="true" formControlName="brokerNotes"></dx-text-area>
            </div>
          </div>
        </div>
      </dxi-item>

      <dxi-item>
        <dxi-location [row]="0" [col]="2"></dxi-location>

        <div class="dx-fieldset">
          <div class="dx-fieldset-header">
            Participation Info
            <span *ngIf="model?.activeMco">: {{ model?.activeMco }} - {{ model?.activeBroker }}</span>
          </div>

          <!--        <div class="dx-field">-->
          <!--          <label class="dx-field-label" style="overflow: visible; text-overflow: initial;">-->
          <!--            <ng-container *ngTemplateOutlet="infoIcon; context: { $implicit: 'Info', idPrefix: 'b2cb6405-6326-41c9-9e98-f50aa0a7fe59' }"></ng-container>-->
          <!--            MCO:-->
          <!--          </label>-->
          <!--          <div class="dx-field-value-static">{{ model?.activeMco }}</div>-->
          <!--        </div>-->
          <!--        -->
          <!--        <div class="dx-field">-->
          <!--          <label class="dx-field-label" style="overflow: visible; text-overflow: initial;">-->
          <!--            <ng-container *ngTemplateOutlet="infoIcon; context: { $implicit: 'Info', idPrefix: 'b73ecf12-d334-41a0-9dbc-b863d8be4375' }"></ng-container>-->
          <!--            Broker:-->
          <!--          </label>-->
          <!--          <div class="dx-field-value-static">{{ model?.activeBroker }}</div>-->
          <!--        </div>-->

          <table style="width: 100%" cellpadding="0" cellspacing="0">
            <tr>
              <td style="width: 38%">
                <div class="dx-field" style="margin-bottom: 5px">
                  <label class="dx-field-label" style="width: 37%">Status *</label>
                  <div class="dx-field-value" style="width: 63%">
                    <dx-select-box
                      [dataSource]="consumerStatuses"
                      displayExpr="Name"
                      valueExpr="ID"
                      formControlName="status">
                      <dx-validator name="Status">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </div>
                </div>
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <div class="dx-field" style="margin-bottom: 5px">
                  <label class="dx-field-label"
                  >Internal ID
                    <ng-container
                      *ngTemplateOutlet="
                        infoIcon;
                        context: { $implicit: tooltips.internalID, idPrefix: 'bbbde84c-b302-4b8d-acea-3c0338e66828' }
                      "></ng-container>
                  </label>
                  <div class="dx-field-value">
                    <dx-text-box formControlName="internalID"></dx-text-box>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="dx-field" style="margin-bottom: 5px">
                  <label class="dx-field-label" style="width: 37%">MCI</label>
                  <div class="dx-field-value" style="width: 63%">
                    <dx-text-box formControlName="mci">
                      <!--                    <dx-validator name="MCI">-->
                      <!--                      <dxi-validation-rule type="required"></dxi-validation-rule>-->
                      <!--                    </dx-validator>-->
                    </dx-text-box>
                  </div>
                </div>
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <div class="dx-field" style="margin-bottom: 5px">
                  <label class="dx-field-label" style="width: 30%"
                  >Program *
                    <ng-container
                      *ngTemplateOutlet="
                        infoIcon;
                        context: { $implicit: tooltips.program, idPrefix: '8abfa41c-9266-4a76-bfff-1c07dd71b769' }
                      "></ng-container>
                  </label>
                  <div class="dx-field-value" style="width: 70%">
                    <dx-select-box
                      [showClearButton]="true"
                      [dataSource]="programDso"
                      displayExpr="name"
                      valueExpr="id"
                      formControlName="programId">
                      <dx-validator name="Program">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="dx-field">
            <label class="dx-field-label"
            >Client Type *
              <ng-container
                *ngTemplateOutlet="
                  infoIcon;
                  context: { $implicit: tooltips.clientType, idPrefix: '91e4340c-e188-4690-a004-d1778a2a6851' }
                "></ng-container>
            </label>
            <div class="dx-field-value">
              <dx-select-box
                [dataSource]="repetitiveModes"
                displayExpr="Name"
                valueExpr="ID"
                formControlName="repetitiveMode"
                [showClearButton]="true">
                <dx-validator name="Client Type">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label" style="margin-top: 10px"
            >Week Days
              <ng-container
                *ngTemplateOutlet="
                  infoIcon;
                  context: { $implicit: tooltips.weekDays, idPrefix: '7238b2cb-08eb-4e8f-a7f6-1da006fe00d7' }
                "></ng-container>
            </label>
            <div class="dx-field-value">
              <mat-form-field style="width: 100%; margin-top: -10px">
                <app-weekday-checkbox formControlName="weekDays"></app-weekday-checkbox>
              </mat-form-field>
            </div>
          </div>

          <div class="dx-field">
            <div style="display: flex; align-items: center">
              <label class="_dx-field-label"
              >Attendance Notes
                <ng-container
                  *ngTemplateOutlet="
                    infoIcon;
                    context: { $implicit: tooltips.attendanceNote, idPrefix: '48554a66-3f73-4a62-88ad-66e95417b20d' }
                  "></ng-container>
              </label>

              <div style="flex: auto">
                <a
                  mat-button
                  color="primary"
                  style="float: right"
                  (click)="addNoteClick($event, true)"
                  *ngIf="!addNoteEnabled"
                >ADD ATTENDANCE NOTE</a
                >
                <a
                  mat-button
                  color="primary"
                  style="float: right"
                  (click)="addNoteClick($event, false)"
                  *ngIf="addNoteEnabled"
                >CLOSE ATTENDANCE NOTE</a
                >
              </div>
            </div>

            <!--          <div class="_dx-field-value">-->
            <!--            <dx-text-area formControlName="notes"></dx-text-area>-->
            <!--          </div>-->

            <ng-container formGroupName="_note" *ngIf="addNoteEnabled">
              <div style="display: flex; justify-content: space-between">
                <div class="dx-field" style="flex: 1">
                  <label class="_dx-field-label">Note Entered By:</label>
                  <div class="_dx-field-value">
                    <dx-text-box formControlName="author">
                      <dx-validator name="Noted By">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>

                <span>&nbsp;&nbsp;</span>

                <div class="dx-field" style="flex: 2">
                  <div class="_dx-field-value">
                    <dx-text-area formControlName="text">
                      <dx-validator name="Text">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-area>
                  </div>
                </div>
              </div>

              <div style="display: flex; justify-content: space-between">
                <div class="dx-field" style="flex: auto">
                  <label class="_dx-field-label">Info Provided By:</label>
                  <div class="_dx-field-value">
                    <dx-text-box formControlName="infoBy">
                      <dx-validator name="Info By">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>

                <span>&nbsp;&nbsp;</span>

                <div class="dx-field">
                  <label class="_dx-field-label">Info Provided On:</label>
                  <div class="_dx-field-value">
                    <dx-date-box formControlName="infoDate"></dx-date-box>
                  </div>
                </div>

                <span>&nbsp;&nbsp;</span>

                <div class="dx-field">
                  <label class="_dx-field-label">Follow Up Date:</label>
                  <div class="_dx-field-value">
                    <dx-date-box formControlName="followUpDate"></dx-date-box>
                  </div>
                </div>
              </div>
            </ng-container>

            <app-consumer-notes [model]="model"></app-consumer-notes>
            <br />
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Default Facility</label>
            <div class="dx-field-value">
              <dx-select-box
                [showClearButton]="true"
                [dataSource]="destinationsDS$ | async"
                [displayExpr]="destinationDisplayExpr"
                valueExpr="short"
                itemTemplate="item"
                formControlName="defaultDestination">
                <div *dxTemplate="let data of 'item'">[{{ data.short }}] {{ data.name }}</div>
              </dx-select-box>
            </div>
          </div>

          <!--        <div class="dx-field">-->
          <!--          <label class="_dx-field-label">Special Instructions-->
          <!--            <ng-container *ngTemplateOutlet="infoIcon; context: { $implicit: 'Info', idPrefix: '77a87676-b87b-4252-abd3-1ad51fe5d64a' }"></ng-container>-->
          <!--          </label>-->
          <!--          <div class="_dx-field-value">-->
          <!--            <dx-text-area formControlName="specialInstrs"></dx-text-area>-->
          <!--          </div>-->
          <!--        </div>-->

          <div class="dx-field">
            <label class="_dx-field-label"
            >Transportation Codes
              <ng-container
                *ngTemplateOutlet="
                  infoIcon;
                  context: { $implicit: tooltips.transpCodes, idPrefix: 'fc38a2ac-febc-4b54-8b9f-677bff48e813' }
                "></ng-container>
            </label>
            <div class="_dx-field-value">
              <dx-tag-box
                formControlName="transpCodes"
                valueExpr="ID"
                displayExpr="Name"
                [items]="transpCodes"></dx-tag-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="_dx-field-label"
            >Transportation Instructions
              <ng-container
                *ngTemplateOutlet="
                  infoIcon;
                  context: { $implicit: tooltips.transpInstructions, idPrefix: '9a59237a-16f3-448b-8524-448ca211c7b4' }
                "></ng-container>
            </label>
            <div class="_dx-field-value">
              <dx-text-area formControlName="transpInstrs"></dx-text-area>
            </div>
          </div>
        </div>

        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Coordination Agency Info</div>

          <div class="dx-field">
            <label class="dx-field-label">Coordination Agency</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="c10nAgency"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Case Worker Name</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="cwName"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Case Worker Phone</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="cwPhone"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Case Worker Fax</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="cwFax"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Case Worker Email</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="cwEmail"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="_dx-field-label">Case Worker Note</label>
            <div class="_dx-field-value">
              <dx-text-area formControlName="cwNote"></dx-text-area>
            </div>
          </div>
        </div>
      </dxi-item>
    </dx-responsive-box>
    <div class="dx-fieldset">
      <!--        <br>-->
      <!--    <mat-divider style="margin: 0 -34px;"></mat-divider>-->
      <!--    <br><br>-->

      <!--    <ng-container *ngIf="stateFull">-->
      <!--    </ng-container>-->

      <ng-container formGroupName="data">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">SOCIAL DISTANCING AREAS OF CONCERN<br />(Check all that apply)</div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_01_iihrucDCg"
                text="Individuals identified as high-risk using current DOH & CDC guidelines"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_02_lnfis"
                text="Limited or no formal or informal supports"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_03_csdacsccu"
                text="Caregiver stress/deficits in ability to care for self and consumer/caregiver unavailable"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_04_apasdi"
                text="Ability of participant to adhere to social distancing issues"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_05_ncwoh"
                text="Need for caregiver to work outside of home"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box
                formControlName="sdac_06_mhced"
                text="Mental health concerns and/or emotional distress"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box formControlName="sdac_07_sil" text="Social isolation/loneliness"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box formControlName="sdac_08_finr" text="Food insecurity/nutritional risks"></dx-check-box>
            </div>
          </div>

          <div class="dx-field">
            <div>
              <dx-check-box #sdac_other formControlName="sdac_09_others" text="Other:"></dx-check-box>
            </div>
            <div style="margin-top: 5px">
              <dx-text-area [readOnly]="sdac_other.value !== true" formControlName="sdac_09_othersText"></dx-text-area>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="dx-fieldset-header">Other Info</div>

      <div class="dx-field" *ngIf="config.get('consumerMoreFields', []).includes('facilityID')">
        <label class="dx-field-label">Facility ID</label>
        <div class="dx-field-value">
          <dx-text-box formControlName="facilityID"></dx-text-box>
        </div>
      </div>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <label class="dx-field-label">ADC Client’s Shift</label>
          <div class="dx-field-value">
            <dx-select-box
              formControlName="adcClientShift"
              [dataSource]="[1, 2]"
              [showClearButton]="true"></dx-select-box>
          </div>
        </div>
      </ng-container>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <label class="dx-field-label">ADC Client’s Shift Per Day</label>
          <div class="dx-field-value">
            <app-map-value-box
              formControlName="adcClientShiftPerDay"
              [source]="[
                { v: 1, t: '1st' },
                { v: 2, t: '2nd' }
              ]"
              [labels]="['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']"></app-map-value-box>
          </div>
        </div>
      </ng-container>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <label class="dx-field-label">ADC Daily Rate</label>
          <div class="dx-field-value">
            <dx-number-box formControlName="adcDailyRate"></dx-number-box>
          </div>
        </div>
      </ng-container>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <label class="dx-field-label">ADC Client's CACFP Eligibility</label>
          <div class="dx-field-value">
            <dx-select-box
              placeholder="N/A"
              formControlName="adcClientCACFPEligibility"
              [dataSource]="adcClientCACFPEligibilityList"
              valueExpr="v"
              displayExpr="t"></dx-select-box>
          </div>
        </div>
      </ng-container>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <label class="dx-field-label">ICD-10</label>
          <div class="dx-field-value">
            <dx-text-box formControlName="icd10"></dx-text-box>
          </div>
        </div>
      </ng-container>

      <!--    <div class="dx-field">-->
      <!--      <label class="dx-field-label">Week Days Notes</label>-->
      <!--      <div class="dx-field-value">-->
      <!--        <dx-text-area formControlName="daysNotes"></dx-text-area>-->
      <!--      </div>-->
      <!--    </div>-->

      <!--<mat-divider></mat-divider>-->

      <ng-container formGroupName="person">
        <!--      <ng-container *ngIf="stateFull">-->

        <div class="dx-field">
          <label class="dx-field-label">Nickname</label>
          <div class="dx-field-value">
            <dx-text-box formControlName="nickname"></dx-text-box>
          </div>
        </div>

        <ng-container formGroupName="data">
          <div class="dx-field">
            <label class="dx-field-label">Native Language Name</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="nativeLangName"></dx-text-box>
            </div>
          </div>
        </ng-container>

        <!--      </ng-container>-->
      </ng-container>

      <br /><br />
      <div class="dx-fieldset-header">
        Related Contacts
        <ng-container
          *ngTemplateOutlet="
            infoIcon;
            context: { $implicit: tooltips.relatedContacts, idPrefix: '12798d5c-b114-45ae-a0d3-3474310a4b5c' }
          "></ng-container>
      </div>

      <ng-container formArrayName="emRelations">
        <br />

        <app-form-array-group
          entityName="relation"
          icon="supervisor_account"
          [formArray]="emRelationsFormArray"
          (addItem)="addFormArrayItem('emRelations')"
          (removeItem)="removeFormArrayItem('emRelations', $event)"></app-form-array-group>
      </ng-container>

      <!-- /// -->
      <p>
        <dx-validation-summary></dx-validation-summary>
      </p>

      <!--    <p class="alert-danger" *ngIf="errorMessage">-->
      <!--      {{ errorMessage }}-->
      <!--    </p>-->
    </div>
  </ng-container>

  <!--  -->

  <ng-container *ngIf="selectedTab === 'Transport'">
    <div class="dx-fieldset">
      <ng-container>
        <fieldset>
          <legend>Scheduling</legend>

          <div class="dx-field">
            <div class="dx-field-label">Auto Dispatch</div>
            <div class="dx-field-value">
              <div>
                <dx-switch formControlName="autoDispatch" switchedOnText="YES" switchedOffText="NO"></dx-switch>
              </div>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">On Boarding Duration</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="onBoardingDuration"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Off Boarding Duration</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="offBoardingDuration"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Keeps stretcher during appt</div>
            <div class="dx-field-value">
              <div>
                <dx-switch formControlName="keepStretcher" switchedOnText="YES" switchedOffText="NO"></dx-switch>
              </div>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Preferred Employees</div>
            <div class="dx-field-value">
              <div>
                <!-- preferredEmployees -->
                <dx-tag-box
                  formControlName="preferredEmployees"
                  [dataSource]="employeesDso$ | async"
                  valueExpr="id"
                  [displayExpr]="employeeHelper.displayExpr"></dx-tag-box>
              </div>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Avoid Employees</div>
            <div class="dx-field-value">
              <div>
                <!-- preferredEmployees -->
                <dx-tag-box
                  formControlName="avoidEmployees"
                  [dataSource]="employeesDso$ | async"
                  valueExpr="id"
                  [displayExpr]="employeeHelper.displayExpr"></dx-tag-box>
              </div>
            </div>
          </div>
        </fieldset>
      </ng-container>
    </div>
  </ng-container>

  <!--  -->

  <ng-container *ngIf="selectedTab === 'Meals'">
    <div class="dx-fieldset">
      <ng-container formGroupName="mealMeta">
        <fieldset>
          <legend>Auth Info</legend>

          <ng-container *ngIf="modelWithDetails$.value?._authClient as member; else noMemberBlock">
            <div class="dx-field">
              <label class="dx-field-label">Member Name</label>
              <div class="dx-field-value-static">
                {{ member.name?.first }}
                {{ member.name?.last }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member DOB</label>
              <div class="dx-field-value-static">
                {{ member.dob | utc : 'L' }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member ID</label>
              <div class="dx-field-value-static">
                {{ member._clientId }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member Phone</label>
              <div class="dx-field-value-static">
                {{ member.phones[0] | onlyDigits }}
              </div>
            </div>
          </ng-container>

          <ng-template #noMemberBlock>No Auths</ng-template>
        </fieldset>

        <br />

        <fieldset>
          <legend>General</legend>

          <div class="dx-field">
            <div class="dx-field-label">Enable Text Message On Meal DropOff</div>
            <div class="dx-field-value">
              <div>
                <dx-switch formControlName="smsOnMealDropOff" switchedOnText="YES" switchedOffText="NO"></dx-switch>
              </div>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Meal Delivery Instructions</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="mealDeliveryI10s"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Cuisine Preference</label>
            <div class="dx-field-value">
              <dx-select-box
                formControlName="cuisinePreference"
                [dataSource]="[
                  'Western/American',
                  'Chinese',
                  'Korean',
                  'Russian',
                  'Ukrainian',
                  'Indian'
                ]"></dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Food Allergies</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="foodAllergies"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Meal General Notes</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="mealGeneralNotes"></dx-text-box>
            </div>
          </div>
        </fieldset>

        <br />

        <dx-tab-panel>
          <ng-container *ngFor="let type of mealTypes">
            <ng-container *ngIf="config.get(mealTypeConfigMap[type], false)">
              <!--              <br>-->

              <dxi-item [title]="mealTypeTitleMap[type]" [formGroupName]="type" style="padding: 1em">
                <!--                <fieldset>-->
                <!--                <legend>{{mealTypeTitleMap[type]}}</legend>-->

                <ng-container *ngIf="modelWithDetails$.value?._mealsAuths[type] as authInfo; else noAuthBlock">
                  <div class="dx-field">
                    <label class="dx-field-label">Auth Number Facets</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.AuthNumberFacets }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Period</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.StartDT | utc : 'L' }}
                      -
                      {{ authInfo.EndDT | utc : 'L' }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Auth Units Approved</label>
                    <div class="dx-field-value-static">
                      {{ modelWithDetails$.value?._mealsEstimated[type]?.done }} of {{ authInfo.AuthUnitsApproved }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Frequency</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.Frequency }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Note</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.Note }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Service Coordinator Name</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.ServiceCoordinatorName }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Service Coordinator Phone</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.ServiceCoordinatorPhone }}
                    </div>
                  </div>

                  <div class="dx-field">
                    <label class="dx-field-label">Service Coordinator Email</label>
                    <div class="dx-field-value-static">
                      {{ authInfo.ServiceCoordinatorEmail }}
                    </div>
                  </div>
                </ng-container>

                <ng-template #noAuthBlock>No Active Auth</ng-template>

                <div class="dx-field">
                  <label class="dx-field-label">{{ mealTypeTitleMap[type] }} Note</label>
                  <div class="dx-field-value">
                    <dx-text-box formControlName="notes"></dx-text-box>
                  </div>
                </div>

                <br />

                <ng-container formGroupName="weekdayMeta">
                  <table class="weekday-meta">
                    <thead>
                    <th></th>
                    <th valign="bottom">
                      <label>Pre-Approved<br />Schedule Units</label>
                    </th>
                    <th valign="bottom"><label>Actual Delivery On</label></th>
                    <th valign="bottom">
                      <label>Actual Delivery<br />Units</label>
                    </th>
                    <th valign="bottom">
                      <label>Preferred Delivery<br />Time</label>
                    </th>
                    <th valign="bottom"><label>Meal Requests</label></th>
                    </thead>

                    <tbody>
                    <tr *ngFor="let d of ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']; let idx = index">
                      <ng-container [formGroupName]="d">
                        <td>
                          <label>{{ d }}</label>
                        </td>

                        <td>
                          <dx-number-box
                            formControlName="schedUnits"
                            [showSpinButtons]="true"
                            width="50px"></dx-number-box>
                        </td>

                        <td>
                          <dx-select-box
                            formControlName="actualDay"
                            [dataSource]="weekDays"
                            displayExpr="Name"
                            valueExpr="ID"
                            [showClearButton]="true"
                            width="130px"></dx-select-box>
                        </td>

                        <td>
                          <dx-number-box
                            formControlName="actualUnits"
                            [showSpinButtons]="true"
                            width="50px"></dx-number-box>
                        </td>

                        <td>
                          <dx-date-box
                            formControlName="schedTime"
                            type="time"
                            pickerType="rollers"
                            [showClearButton]="true"
                            dateSerializationFormat="HH:mm"
                            width="110px"></dx-date-box>
                        </td>

                        <td>
                          <dx-text-box formControlName="mealRequests" width="250px"></dx-text-box>
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </ng-container>

                <!--                </fieldset>-->
              </dxi-item>
            </ng-container>
          </ng-container>
        </dx-tab-panel>
      </ng-container>
    </div>
  </ng-container>

  <!--  -->

  <ng-container *ngIf="selectedTab === 'PERS'">
    <div class="dx-fieldset">
      <!--      <div class="dx-field">-->
      <!--        <label class="dx-field-label">Legacy Customer ID</label>-->
      <!--        <div class="dx-field-value">-->
      <!--          <dx-text-box formControlName="legacyCustomerID"></dx-text-box>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dx-field">
        <label class="dx-field-label">Telecare Informed Consent Document Signature Date *</label>
        <div class="dx-field-value">
          <dx-date-box formControlName="tcInformedConsentDocumentSignatureDate"
                       applyValueMode="useButtons"
          ></dx-date-box>
        </div>
      </div>

      <!--      <div class="dx-field">-->
      <!--        <label class="dx-field-label">Telecare Informed Consent File</label>-->

      <!--        <div class="dx-field-value">-->
      <!--&lt;!&ndash;&lt;!&ndash;            (onInitialized)="uploader1_onInitialized($event)"&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;          <dx-file-uploader&ndash;&gt;-->
      <!--&lt;!&ndash;            #uploader1&ndash;&gt;-->
      <!--&lt;!&ndash;          >&ndash;&gt;-->
      <!--&lt;!&ndash;            <dx-validator name="Telecare Informed Consent File" #uploadValidator1>&ndash;&gt;-->
      <!--&lt;!&ndash;&lt;!&ndash;              <dxi-validation-rule *ngIf="!model" type="required"></dxi-validation-rule>&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;              <dxi-validation-rule&ndash;&gt;-->
      <!--&lt;!&ndash;                type="custom"&ndash;&gt;-->
      <!--&lt;!&ndash;                [reevaluate]="true"&ndash;&gt;-->
      <!--&lt;!&ndash;                [validationCallback]="validationCallback"&ndash;&gt;-->
      <!--&lt;!&ndash;              ></dxi-validation-rule>&ndash;&gt;-->
      <!--&lt;!&ndash;            </dx-validator>&ndash;&gt;-->
      <!--&lt;!&ndash;          </dx-file-uploader>&ndash;&gt;-->

      <!--          <dx-text-box formControlName="tcInformedConsentFile" [readOnly]="true"></dx-text-box>-->
      <!--        </div>-->
      <!--      </div>-->

<!--      <div class="dx-field">-->
<!--        <div class="dx-field-label">Last DateTime Of Successful Test *</div>-->
<!--        <div class="dx-field-value">-->
<!--          <dx-date-box-->
<!--            formControlName="persLastSuccessfulTest"-->
<!--            applyValueMode="useButtons"-->
<!--            type="datetime"-->
<!--          ></dx-date-box>-->
<!--        </div>-->
<!--      </div>-->

      <!--      <div class="dx-field">-->
      <!--        <label class="dx-field-label">Total Number of Alerts over past 30 days</label>-->
      <!--        <div class="dx-field-value">-->
      <!--          <dx-number-box formControlName="persAlertsLast30D" [readOnly]="true"></dx-number-box>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="dx-field">-->
      <!--        <label class="dx-field-label">Total Number of Alerts over past 90 days</label>-->
      <!--        <div class="dx-field-value">-->
      <!--          <dx-number-box formControlName="persAlertsLast90D" [readOnly]="true"></dx-number-box>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="dx-field">-->
      <!--        <label class="dx-field-label">Total Number of Alerts over past 12 months</label>-->
      <!--        <div class="dx-field-value">-->
      <!--          <dx-number-box formControlName="persAlertsLast12M" [readOnly]="true"></dx-number-box>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dx-field">
        <label class="dx-field-label">Notes</label>
        <div class="dx-field-value">
          <dx-text-area formControlName="persNotes"></dx-text-area>
        </div>
      </div>

    </div>

    <div class="dx-fieldset-header">Device Info</div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <label class="dx-field-label">PERS Device List</label>
        <div class="dx-field-value">
          <dx-select-box
            [dataSource]="persDevicesDS"
            [displayExpr]="persDevice_displayExpr"
            valueExpr="_id"
            [value]="activePersDeviceId"
            (onSelectionChanged)="setPersDeviceFormValue($event)"
            [showClearButton]="true"
          ></dx-select-box>
        </div>
      </div>

      <ng-container formGroupName="_persDevice">
        <fieldset>
          <legend>PERS Device</legend>

          <!--          <input type="hidden" formControlName="_id"/>-->

          <div class="dx-field">
            <label class="dx-field-label">Device Last Successful Test Date *</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="lastSuccessfulTestDate" applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Status *</label>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="['ACTIVE', 'INACTIVE']" formControlName="status"></dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Type *</label>
            <div class="dx-field-value">
              <dx-select-box formControlName="type"
                             [dataSource]="[
                  'In-home landline',
                  'In-home landline w/ Fall Detection',
                  'In-home cellular',
                  'In-home cellular w/ Fall Detection',
                  'Mobile GPS',
                  'Mobile GPS w/ Fall Detection',
                  'Mobile GPS Watch',
                  'Mobile GPS Watch w/ Fall Detection',
                  'In-home cellular Handsfree/ Voice Activated',
                  'Sensor & Activity Monitoring',
                  'Medication Dispensing & Monitoring',
                  'Other'
                ]"
              ></dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Device Phone Number *</label>
            <div class="dx-field-value">
              <dx-text-box mask="0000000000" formControlName="phoneNumber"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Installation Details *</label>
            <div class="dx-field-value">
              <dx-select-box formControlName="installationDetails"
                             [dataSource]="[
                  'Drop shipment w/ virtual installation/training',
                  'On-site installation/training',
                  'Participant Refused',
                  'Participant Unable to Be Reached',
                  'Provider Unable to Install'
                ]"
              ></dx-select-box>
              <!--              <dx-text-area formControlName="installationDetails"></dx-text-area>-->
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Date of Initial Live Test *</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="initialLiveTestDate" applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Date of Last Successful Testing Adaptive Equipment (add ons) (Sip & Puff,
              Headswitch etc.)</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="lastSuccessfulTestingAdaptiveEquipment"
                           applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Make</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="make"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Model</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="model"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">IMEI</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="imei"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Notes</label>
            <div class="dx-field-value">
              <dx-text-area formControlName="notes"></dx-text-area>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Date Delivered</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="deliveredDate" applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Delivery Confirmation</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="deliveryConfirmation" [readOnly]="true"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Shipping Label File PDF</label>

            <div class="dx-field-value">
              <!--              <dx-file-uploader #uploader2>-->
              <!--                <dx-validator name="Shipping Label File PDF" #uploadValidator2>-->
              <!--                  &lt;!&ndash;              <dxi-validation-rule *ngIf="!model" type="required"></dxi-validation-rule>&ndash;&gt;-->
              <!--                  <dxi-validation-rule-->
              <!--                    type="custom"-->
              <!--                    [reevaluate]="true"-->
              <!--                    [validationCallback]="validationCallback"-->
              <!--                  ></dxi-validation-rule>-->
              <!--                </dx-validator>-->
              <!--              </dx-file-uploader>-->

              <dx-text-box formControlName="shippingLabelFile" [readOnly]="true"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Date of Installation</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="installationDate" applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Date of Virtual Training</label>
            <div class="dx-field-value">
              <dx-date-box formControlName="virtualTrainingDate" applyValueMode="useButtons"></dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Virtual Training Conducted By</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="virtualTrainingConductedBy"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <label class="dx-field-label">Virtual Training Notes</label>
            <div class="dx-field-value">
              <dx-text-box formControlName="virtualTrainingNotes"></dx-text-box>
            </div>
          </div>
        </fieldset>
      </ng-container>

    </div>

  </ng-container>

</dx-validation-group>

<ng-template #infoIcon let-content let-idPrefix="idPrefix">
  <span [attr.id]="'_' + idPrefix + '-trgt'">
    <mat-icon [inline]="true" fontSet="fas" fontIcon="fa-info-circle" color="primary" style="width: 1.2em"></mat-icon>
    <dx-popover
      [target]="'#_' + idPrefix + '-trgt'"
      showEvent="dxhoverstart"
      hideEvent="dxhoverend"
      minWidth="400px"
      maxWidth="600px">
      <div *dxTemplate="let data of 'content'">
        <div [innerHTML]="content | sanitizeHtml"></div>
      </div>
    </dx-popover>
  </span>
</ng-template>


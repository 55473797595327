
declare var Object: any;
export interface ContactViewInterface {
  "id": number;
  "notes"?: string;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "addresses_0_id"?: string;
  "addresses_0_street"?: string;
  "addresses_0_city"?: string;
  "addresses_0_state"?: string;
  "addresses_0_zip"?: string;
  "phones_0_id"?: string;
  "phones_0_value"?: string;
  "emails_0_id"?: string;
  "emails_0_value"?: string;
}

export class ContactView implements ContactViewInterface {
  "id": number;
  "notes": string;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "addresses_0_id": string;
  "addresses_0_street": string;
  "addresses_0_city": string;
  "addresses_0_state": string;
  "addresses_0_zip": string;
  "phones_0_id": string;
  "phones_0_value": string;
  "emails_0_id": string;
  "emails_0_value": string;
  constructor(data?: ContactViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ContactView`.
   */
  public static getModelName() {
    return "ContactView";
  }

  /**
  * This method creates an instance of ContactView for dynamic purposes.
  **/
  public static factory(data: ContactViewInterface): ContactView{
    return new ContactView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ContactView',
      plural: 'ContactViews',
      path: 'ContactViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "addresses_0_id": {
          name: 'addresses_0_id',
          type: 'string'
        },
        "addresses_0_street": {
          name: 'addresses_0_street',
          type: 'string'
        },
        "addresses_0_city": {
          name: 'addresses_0_city',
          type: 'string'
        },
        "addresses_0_state": {
          name: 'addresses_0_state',
          type: 'string'
        },
        "addresses_0_zip": {
          name: 'addresses_0_zip',
          type: 'string'
        },
        "phones_0_id": {
          name: 'phones_0_id',
          type: 'string'
        },
        "phones_0_value": {
          name: 'phones_0_value',
          type: 'string'
        },
        "emails_0_id": {
          name: 'emails_0_id',
          type: 'string'
        },
        "emails_0_value": {
          name: 'emails_0_value',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}

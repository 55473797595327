<app-content-w-header header="Notifications" xmlns="http://www.w3.org/1999/html">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <form
      [formGroup]="form"
      (ngSubmit)="form_ngSubmit($event)"
      style="flex: auto; display: flex; flex-direction: column">
      <div style="flex: auto; position: relative">
        <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
          <dx-scroll-view>
            <mat-card>
              <div class="dx-fieldset">
                <fieldset>
                  <legend style="text-decoration: underline">Operations Notifications</legend>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      OPS Alert
                      <i
                        class="fas fa-info-circle"
                        title="Email Alert on Drivers Did not checking by scheduled start time"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Minutes Late
                      <i
                        class="fas fa-info-circle"
                        title="Minutes Late to work to trigger Individual Email Alert on Drivers Did not checking by scheduled start time"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-number-box
                          formControlName="opsAlertUsersMinutesLate"
                          [min]="5"
                          [max]="60"
                          [showSpinButtons]="true"
                          [showClearButton]="true"
                          [value]="0"></dx-number-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      OPS Alert - Individual Driver Late To Work (Email)
                      <i
                        class="fas fa-info-circle"
                        title="Individual Email Alert on Drivers Did not checking by scheduled start time"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersIndividual"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      OPS Alert - Individual Driver Late To Work (SMS)
                      <i
                        class="fas fa-info-circle"
                        title="Individual SMS Alert on Drivers Did not checking by scheduled start time"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersIndividualSms"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Minutes Late
                      <i class="fas fa-info-circle" title=""></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-number-box
                          formControlName="opsAlertUsersIndividualMinutesLate"
                          [min]="5"
                          [max]="60"
                          [showSpinButtons]="true"
                          [showClearButton]="true"
                          [value]="0"></dx-number-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      OPS Alert - Drivers Checked In With Other Vehicle
                      <i class="fas fa-info-circle" title="Email Alert on Drivers checked in with other vehicle"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersCheckedInWithOtherVehicle"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      New Client Auto Created
                      <i class="fas fa-info-circle" title="Email Alert on New Client Auto Created"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersNewClientAutoCreated"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Email Alert on Clients Updates
                      <i class="fas fa-info-circle" title="Email Alert on Clients Updates"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersClientUpdate"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Entity Create/Update/Delete (Raw JSON object)
                      <i class="fas fa-info-circle" title="Email Alert on Entity Create/Update/Delete"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="opsAlertUsersEntityActions"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="dx-fieldset">
                <fieldset>
                  <legend style="text-decoration: underline">Fleet Notifications</legend>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Fleet Status Report (FSR)
                      <i
                        class="fas fa-info-circle"
                        title="Comprehensive Email Report On Status of Fleet, including Fleet Stats, Fleet Alerts on Expiring Document, Reported Fleet Issues"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="fleetStatusReportUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Fleet Backup Report (FBR)
                      <i class="fas fa-info-circle" title="Email Report listing all vehicles in Backup Status"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="fleetBackupReportUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Fleet Maintenance Report (FMR)
                      <i
                        class="fas fa-info-circle"
                        title="Email Report listing all vehicles in maintenance status on the moment of report execution"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="fleetMaintenanceReportUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Vehicle Status Change Notification
                      <i
                        class="fas fa-info-circle"
                        title="Email Notification triggered every time vehicle status is changes"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="vehicleStatusChangeNotificationUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Backup Status Automatic Change to Assigned
                      <i
                        class="fas fa-info-circle"
                        title="Email Notification Triggered Every Time When Vehicle was changed from Backup Status to Assigned in Automatic mode, this happens when vehicle in Backup status was used for few days on the same facility."></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="vehicleBackup2AssignedAutoChangeNotificationUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="dx-fieldset">
                <fieldset>
                  <legend style="text-decoration: underline">Sheduling Notifications</legend>
                  <div class="dx-field">
                    <div class="dx-field-label">
                      Unresolved ETD Issues
                      <i class="fas fa-info-circle" title="Email Notification About Unresolved ETD Issues"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="unresolvedEtdIssuesUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="dx-fieldset">
                <fieldset>
                  <legend style="text-decoration: underline">HR Notifications</legend>
                  <div class="dx-field">
                    <div class="dx-field-label">
                      HR Notification (HRN)
                      <i
                        class="fas fa-info-circle"
                        title="Email Notification About Expiring And Missing Employee Documents"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="hrNotificationUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      HR PTO Request Notification
                      <i
                        class="fas fa-info-circle"
                        title="Personal Time Off Request Notification Submitted on Driver App"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="hrPtoRequestNotificationUsers"
                          [dataSource]="usersDso"
                          valueExpr="id"
                          [displayExpr]="displayExpr"
                          [searchExpr]="searchExpr"
                          [searchEnabled]="true"></dx-tag-box>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="dx-fieldset">
                <dx-validation-summary></dx-validation-summary>
                <dx-toolbar>
                  <dxi-item location="after">
                    <dx-button type="normal" text="Reset" (onClick)="reset_onClick($event)"></dx-button>
                  </dxi-item>

                  <dxi-item location="after">
                    <dx-button
                      type="default"
                      text="Submit"
                      [useSubmitBehavior]="true"
                      [icon]="processing ? 'fas fa-circle-notch fa-spin' : null"
                      [disabled]="processing"></dx-button>
                  </dxi-item>
                </dx-toolbar>
              </div>
            </mat-card>
          </dx-scroll-view>
        </div>
      </div>
    </form>
  </div>
</app-content-w-header>


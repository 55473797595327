<kendo-splitter orientation="vertical">
  <kendo-splitter-pane #splitPane [scrollable]="false" size="40%" [collapsible]="true" [collapsed]="true">
    <app-manifest-map
      *ngIf="!splitPane.collapsed"
      [manifestId]="this.manifest?.id"
      [recIds]="this.grid?.selectedRowKeys"
    ></app-manifest-map>
  </kendo-splitter-pane>

  <kendo-splitter-pane [scrollable]="false">
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
        <app-trip-manifest-sub-toolbar
          [modelId]="modelId"
          [selectedDate]="$selectedDate$ | async"
          [manifest]="manifest"
          [validationSummary]="validationSummary"
          [showSubscriptionTrips]="manifestSettings.showSubscriptionTrips"
        ></app-trip-manifest-sub-toolbar>
      </dxi-item>

      <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
        <div style="position: relative; flex: auto">
          <div style="position: absolute; display: flex; top: 0; bottom: 0; left: 0; right: 0">
            <dx-data-grid
              #grid
              [id]="gridTarget"
              [width]="'100%'"
              [height]="'100%'"
              [errorRowEnabled]="false"
              [dataSource]="ds"
              [stateStoring]="grid_stateStoring"
              (onInitialized)="grid_onInitialized($event)"
              (onToolbarPreparing)="grid_onToolbarPreparing($event)"
              (onCellPrepared)="grid_onCellPrepared($event)"
              (onCellHoverChanged)="grid_onCellHoverChanged($event)"
              (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
              (onInitNewRow)="grid_onInitNewRow($event)"
              (onEditingStart)="grid_onEditingStart($event)"
              (onRowUpdating)="grid_onRowUpdating($event)"
              (onSaved)="grid_onSaved($event)"
            >
              <!--                      [repaintChangesOnly]="true"-->

              <!-- Settings -->

              <dxo-group-panel [visible]="false"></dxo-group-panel>

              <dxo-editing
                mode="cell"
                [allowAdding]="!!manifest?.id"
                [allowUpdating]="!!manifest?.id"
                [allowDeleting]="!!manifest?.id"></dxo-editing>

              <dxo-selection [mode]="'multiple'" [deferred]="false" [selectAllMode]="'page'"></dxo-selection>

              <dxo-paging [pageSize]="config.get('itemsPerPage', 100)"></dxo-paging>

              <dxo-pager [allowedPageSizes]="[50, 100, 300]"></dxo-pager>

              <!-- Columns -->

              <dxi-column
                caption="Broker"
                dataType="string"
                [dataField]="helper.getRecFieldMap.broker"
                [allowEditing]="true"></dxi-column>

              <dxi-column
                caption="Trip ID"
                dataType="string"
                [dataField]="helper.getRecFieldMap.tripId"
                [allowEditing]="true"></dxi-column>

              <!-- -->
              <dxi-column
                caption="Service Type"
                [dataField]="helper.getRecFieldMap.serviceType"
                [fixed]="true"
                [fixedPosition]="'left'"
                [showInColumnChooser]="false">
                <dxo-lookup [dataSource]="serviceTypes"></dxo-lookup>
              </dxi-column>

              <!--              <dxi-column-->
              <!--                *ngIf="(tenantType | async) === 'MEALS'"-->
              <!--                caption="Service Type"-->
              <!--                [dataField]="helper.getRecFieldMap.serviceType"-->
              <!--                dataType="string"-->
              <!--                [fixed]="true"-->
              <!--                [fixedPosition]="'left'"-->
              <!--                [allowEditing]="false"-->
              <!--              ></dxi-column>-->
              <!-- -->

              <dxi-column
                caption="Origin"
                [dataField]="helper.getRecFieldMap.origin"
                [fixed]="true"
                [fixedPosition]="'left'"
                [showInColumnChooser]="false"
                editCellTemplate="origin_editCell">
                <!-- <dxo-lookup [dataSource]="originItems" [displayExpr]="'Name'" [valueExpr]="'ID'"></dxo-lookup> -->
                <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
              </dxi-column>

              <dxi-column
                caption="Destination"
                [dataField]="helper.getRecFieldMap.destination"
                [fixed]="true"
                [fixedPosition]="'left'"
                [showInColumnChooser]="false"
                editCellTemplate="destination_editCell">
                <!-- <dxo-lookup [dataSource]="destItems" [displayExpr]="'Name'" [valueExpr]="'ID'"></dxo-lookup> -->
                <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
              </dxi-column>

              <!--<dxi-column caption="Driver"-->
              <!--[dataField]="e"-->
              <!--[showInColumnChooser]="false"-->
              <!--&gt;-->
              <!--<dxo-lookup [dataSource]="driversDSO" [displayExpr]="employee_displayExpr" [valueExpr]="'id'"></dxo-lookup>-->
              <!--<dxi-validation-rule type="custom" [validationCallback]="driver_validationCallback"></dxi-validation-rule>-->
              <!--</dxi-column>-->

              <dxi-column
                caption="Driver"
                [dataField]="helper.getRecFieldMap.employeeId"
                dataType="string"
                [calculateDisplayValue]="'getEmployeeFullName'"
                [calculateFilterExpression]="calculateDriverFilterExpression"
                [showInColumnChooser]="false"
                editCellTemplate="driver_editCell">
                <!--            <dxi-validation-rule type="custom" [validationCallback]="driver_validationCallback"></dxi-validation-rule>-->
              </dxi-column>

              <dxi-column
                caption="Driver Start Time"
                dataField="schedule.startTime"
                dataType="date"
                format="shortTime"
                editCellTemplate="schedule_editCell">
              </dxi-column>

              <dxi-column
                caption="Driver Start Location"
                dataField="schedule.startLocationAddress"
                dataType="date"
                format="shortTime"
                editCellTemplate="schedule_editCell">
              </dxi-column>

              <dxi-column
                caption="Vehicle"
                [dataField]="helper.getRecFieldMap.vehicleId"
                dataType="string"
                [calculateDisplayValue]="'getVehicleTitle'"
                [calculateFilterExpression]="calculateVehicleFilterExpression"
                editCellTemplate="vehicle_editCell"></dxi-column>

              <dxi-column
                caption="Escort"
                [dataField]="helper.getRecFieldMap.escortId"
                dataType="string"
                [calculateDisplayValue]="'getEscortFullName'"
                [calculateFilterExpression]="calculateEscortFilterExpression"
                editCellTemplate="escort_editCell">
                <!--            <dxi-validation-rule type="custom" [validationCallback]="driver_validationCallback"></dxi-validation-rule>-->
              </dxi-column>

              <!--<dxi-column caption="Round Trip"-->
              <!--[dataField]="rt"-->
              <!--dataType="boolean"-->
              <!--[showInColumnChooser]="false"-->
              <!--&gt;</dxi-column>-->

              <dxi-column
                caption="Trip"
                [dataField]="helper.getRecFieldMap.trip"
                dataType="number"
                width="40"
                [showInColumnChooser]="false"
                [editorOptions]="{ min: 1, max: helper.getMaxTrips, showSpinButtons: true, showClearButton: false }">
                <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
              </dxi-column>

              <dxi-column
                caption="PU Time"
                width="90"
                [dataField]="helper.getRecFieldMap.time"
                dataType="date"
                format="shortTime"
                [showInColumnChooser]="false"
                [editorOptions]="{
                  type: 'time',
                  interval: helper.getTimeInterval,
                  displayFormat: null,
                  pickerType: 'native'
                }">
                <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
              </dxi-column>

              <dxi-column
                *ngIf="manifestSettings.showDropOffTime"
                caption="DO Time"
                width="90"
                [dataField]="helper.getRecFieldMap.dropOffTime"
                dataType="date"
                format="shortTime"
                [showInColumnChooser]="false"
                [allowEditing]="false">
              </dxi-column>

              <dxi-column
                caption="Appointment Time"
                width="90"
                [dataField]="helper.getRecFieldMap.appointmentTime"
                dataType="date"
                format="shortTime"
                [editorOptions]="{
                  type: 'time',
                  interval: helper.getTimeInterval,
                  displayFormat: null,
                  pickerType: 'native'
                }"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="#"
                [dataField]="helper.getRecFieldMap.seat"
                dataType="number"
                width="40"
                [allowEditing]="!config.get('tripAutoSequence', false)"></dxi-column>

              <dxi-column
                *ngIf="
                  ['MEALS', 'MIXED'].includes(config.get('serviceType', 'PARATRANSIT')) &&
                  config.get('meals_Hot_W1759_Enabled', false)
                "
                caption="Hot Meals Units"
                width="40"
                [dataField]="helper.getRecFieldMap.mealsUnits1"
                dataType="number"
                [editorOptions]="{ min: 0, showSpinButtons: true, showClearButton: false }"
                [allowEditing]="true">
                <dxi-validation-rule
                  type="custom"
                  [validationCallback]="validateMealsUnit"
                  message="Hot Meals Units number is expected">
                </dxi-validation-rule>
              </dxi-column>

              <dxi-column
                *ngIf="
                  ['MEALS', 'MIXED'].includes(config.get('serviceType', 'PARATRANSIT')) &&
                  config.get('meals_Special_W1764_Enabled', false)
                "
                caption="Special Meals Units"
                width="40"
                [dataField]="helper.getRecFieldMap.mealsUnits2"
                dataType="number"
                [editorOptions]="{ min: 0, showSpinButtons: true, showClearButton: false }"
                [allowEditing]="true">
                <dxi-validation-rule
                  type="custom"
                  [validationCallback]="validateMealsUnit"
                  message="Special Meals Units number is expected">
                </dxi-validation-rule>
              </dxi-column>

              <!--<dxi-column caption="Consumer"-->
              <!--[dataField]="c"-->
              <!--[showInColumnChooser]="false"-->
              <!--&gt;-->
              <!--<dxo-lookup [dataSource]="consumerDSO" [displayExpr]="helper.consumer_displayExpr" [valueExpr]="'id'"></dxo-lookup>-->
              <!--<dxi-validation-rule type="required"></dxi-validation-rule>-->
              <!--&lt;!&ndash;<dxi-validation-rule type="custom" [validationCallback]="consumer_validationCallback"></dxi-validation-rule>&ndash;&gt;-->
              <!--</dxi-column>-->

              <dxi-column
                caption="Consumer"
                [dataField]="helper.getRecFieldMap.consumerId"
                dataType="string"
                [showInColumnChooser]="false"
                [calculateDisplayValue]="'getConsumerFullName'"
                [calculateFilterExpression]="calculateConsumerFilterExpression"
                editCellTemplate="consumer_editCell">
                <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
              </dxi-column>

              <!--<dxi-column caption="Address"-->
              <!--dataType="object"-->
              <!--[dataField]="consumer.person.contact.adresses.0"-->
              <!--[calculateCellValue]="address_calculateCellValue"-->
              <!--[calculateSortValue]="address_calculateCellValue"-->
              <!--[allowEditing]="false"-->
              <!--&gt;</dxi-column>-->

              <dxi-column
                caption="Address"
                dataType="string"
                dataField="getFullAddress"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="Phone"
                dataType="string"
                dataField="getMainPhone"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="Instructions"
                dataType="string"
                dataField="getTranspInstrs"
                cellTemplate="tagBox_cell"
                [allowEditing]="false"></dxi-column>

              <dxi-column
                caption="Special Instructions"
                dataType="string"
                dataField="getSpecialInstrs"
                [allowEditing]="false"
                [showInColumnChooser]="true"
                [visible]="true"></dxi-column>

              <dxi-column
                caption="Last Note"
                dataType="string"
                dataField="getNotes"
                [allowEditing]="false"
                [showInColumnChooser]="true"
                [visible]="true"
              ></dxi-column>

              <dxi-column
                caption="Daily Note"
                dataType="string"
                [dataField]="helper.getRecFieldMap.dailyNote"
                [showInColumnChooser]="true"
                [visible]="dailyNoteVisible"
              ></dxi-column>

              <dxi-column
                caption="Broker Note"
                dataType="string"
                [dataField]="helper.getRecFieldMap.brokerNote"
                [showInColumnChooser]="true"
                [visible]="brokerNoteVisible"
                [allowEditing]="false"
              ></dxi-column>

              <dxi-column
                caption="One Time Trip"
                dataType="boolean"
                [dataField]="helper.getRecFieldMap.oneTimeTrip"
                [showInColumnChooser]="true"
                [visible]="false"
                [showEditorAlways]="true"
              ></dxi-column>

              <!-- Summary -->

              <!-- Templates -->

              <!-- /// -->

              <ng-container *dxTemplate="let data of 'reassignCtrl'">
                <app-toolbar-reassign-btn
                  [manifest]="manifest"
                  [selectedDriverId]="modelId"
                  [grid]="grid"
                  (reassigned)="reassign_onReassigned($event)"
                ></app-toolbar-reassign-btn>
              </ng-container>

              <!-- /// -->

              <div *dxTemplate="let data of 'printCtrl'">
                <app-toolbar-print-btn (action)="printBtn_onAction($event)"></app-toolbar-print-btn>
              </div>

              <!-- /// -->

              <ng-container *dxTemplate="let cellInfo of 'origin_editCell'">
                <dx-lookup
                  [dataSource]="originDS"
                  [grouped]="true"
                  [displayExpr]="'Name'"
                  [valueExpr]="'ID'"
                  [value]="cellInfo.value"
                  (onValueChanged)="originLookup_onValueChanged(cellInfo, $event)"></dx-lookup>
              </ng-container>

              <ng-container *dxTemplate="let cellInfo of 'destination_editCell'">
                <dx-lookup
                  [dataSource]="destDS"
                  [grouped]="true"
                  [displayExpr]="'Name'"
                  [valueExpr]="'ID'"
                  [value]="cellInfo.value"
                  (onValueChanged)="destLookup_onValueChanged(cellInfo, $event)"></dx-lookup>
              </ng-container>

              <!-- /// -->

              <ng-container *dxTemplate="let cellInfo of 'driver_editCell'">
                <app-drop-down-grid-employees
                  [manifest]="manifest"
                  [value]="cellInfo.value || modelId"
                  (valueChanged)="driverDropDown_onValueChanged(cellInfo, $event)"></app-drop-down-grid-employees>
              </ng-container>

              <ng-container *dxTemplate="let cellInfo of 'schedule_editCell'">
                <button
                  class="btn dx-toolbar-button btn-edit-schedule"
                  type="button"
                  matTooltip="Edit Schedule"
                  (click)="editSchedule_onClick(cellInfo)">
                  <i class="dx-icon-edit"></i>
                </button>
              </ng-container>

              <ng-container *dxTemplate="let cellInfo of 'escort_editCell'">
                <app-drop-down-grid-employees
                  [manifest]="manifest"
                  [value]="cellInfo.value || modelId"
                  [filter]="escortFilter"
                  (valueChanged)="escortDropDown_onValueChanged(cellInfo, $event)"
                ></app-drop-down-grid-employees>
              </ng-container>

              <!-- /// -->

              <ng-container *dxTemplate="let cellInfo of 'vehicle_editCell'">
                <app-drop-down-grid-vehicles
                  [manifest]="manifest"
                  [value]="cellInfo.value"
                  (valueChanged)="vehicleDropDown_onValueChanged(cellInfo, $event)"></app-drop-down-grid-vehicles>
              </ng-container>

              <!-- /// -->

              <ng-container *dxTemplate="let cellInfo of 'consumer_editCell'">
                <app-drop-down-grid-consumers
                  [manifest]="manifest"
                  [value]="cellInfo.value"
                  (valueChanged)="consumerDropDown_onValueChanged(cellInfo, $event)"></app-drop-down-grid-consumers>
              </ng-container>

              <!-- /// -->

              <ng-container *dxTemplate="let cellInfo of 'tagBox_cell'">
                <span [title]="cellInfo.value | split | trimArr | join : ', '">
                  <span class="badge" *ngFor="let item of cellInfo.value | split">{{ item }}</span>
                </span>
              </ng-container>
            </dx-data-grid>

            <dx-tooltip
              #tripTooltip
              [target]="cellTooltipTarget"
              [visible]="!!cellTooltipTarget && cellTooltipVisible"
              [closeOnOutsideClick]="false">
              <!--              [position]="cellTooltipPosition"-->
              <div *dxTemplate="let data = data; of: 'content'">
                <div [innerHTML]="cellTooltipContent$ | async | sanitizeHtml"></div>
              </div>
            </dx-tooltip>
          </div>
        </div>
      </dxi-item>

      <dxi-item [ratio]="0" [shrink]="0" [baseSize]="0">
        <dx-popup
          #popupCreateManifest
          [dragEnabled]="false"
          [showTitle]="false"
          [showCloseButton]="false"
          [visible]="false"
          [width]="480"
          [height]="120"
          [elementAttr]="{ class: 'grid-popup' }">
          <ng-container *dxTemplate="let data of 'content'">
            <div style="text-align: center; margin: 10px 0 0">
              <div style="font-size: 25px">There are no manifests for {{ $selectedDate$ | async | date : 'EEEE' }}</div>
              <div>
                Clone Employee Schedule with Manifest <input type="checkbox" [(ngModel)]="cloneSchedulesWithManifest" />
              </div>
            </div>
          </ng-container>
        </dx-popup>
      </dxi-item>
    </dx-box>
  </kendo-splitter-pane>
</kendo-splitter>


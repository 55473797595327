<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset" *ngIf="selectedTabIdx === 0">
    <div class="dx-fieldset-header">Vehicle Info</div>

    <div class="dx-field">
      <div class="dx-field-label">Internal ID *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="internalId" [readOnly]="!!modelId">
          <dx-validator name="Internal ID">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Insurance *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="STATES" formControlName="state">
          <dx-validator name="State">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Status *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="statuses"
          (onSelectionChanged)="status_onSelectionChanged($event)"
          formControlName="status"
          #status>
          <dx-validator name="Status">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="status.value && vehicleStatuses[status.value].subStatuses.length">
      <div class="dx-field-label">Sub-Status *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="vehicleStatuses[status.value].subStatuses"
          formControlName="subStatus"></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Location</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="locationsDs$ | async"
          formControlName="location"
          [acceptCustomValue]="true"
          placeholder="Select or Edit..."></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Assigned Facility</div>
      <div class="dx-field-value">
        <dx-select-box
          formControlName="assignedFacility"
          [dataSource]="facilityDso$ | async"
          [showClearButton]="true"
          valueExpr="ID"
          displayExpr="TITLE"></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Make *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="make">
          <dx-validator name="Make">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Model *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="model">
          <dx-validator name="Model">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Year *</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="year">
          <dx-validator name="Year">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">VIN *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="vin">
          <dx-validator name="VIN">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Title Number</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="titleNumber">
          <!--          <dx-validator name="Title Number">-->
          <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
          <!--          </dx-validator>-->
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">License State *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="licenseState">
          <dx-validator name="License State">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">License Plate Number *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="licensePlateNumber">
          <dx-validator name="License Plate Number">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <!-- <div class="dx-field">
      <div class="dx-field-label">
        Registration Expiration Date<ng-container *ngIf="form.get('state').value === STATES[0]"> *</ng-container>
      </div>
      <div class="dx-field-value">
        <dx-date-box formControlName="registrationExp">
          <dx-validator name="Registration Expiration Date">
            <dxi-validation-rule type="required" *ngIf="form.get('state').value === STATES[0]"></dxi-validation-rule>
          </dx-validator>
        </dx-date-box>
      </div>
    </div> -->

    <div class="dx-field">
      <div class="dx-field-label">
        Inspection Expiration Date<ng-container *ngIf="form.get('state').value === STATES[0]"> *</ng-container>
      </div>
      <div class="dx-field-value">
        <dx-date-box formControlName="inspectionExp">
          <dx-validator name="Inspection Expiration Date">
            <dxi-validation-rule type="required" *ngIf="form.get('state').value === STATES[0]"></dxi-validation-rule>
          </dx-validator>
        </dx-date-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Vehicle Color</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="color"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Notes</div>
      <div class="dx-field-value">
        <app-any-notes
          [form]="form"
          [notes]="model?.anyNotes"
          [addDialogNoteInputs]="addDialogNoteInputs"
          [configMap]="{ vehicleId: [] }"
          (validateAsync)="validateAsync()"></app-any-notes>
      </div>
    </div>

    <ng-container formGroupName="meta"> </ng-container>
  </div>

  <!-- -->

  <div class="dx-fieldset" *ngIf="selectedTabIdx === 1">
    <div class="dx-fieldset-header">Vehicle Configuration</div>

    <div class="dx-field">
      <div class="dx-field-label">Fuel *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="['GAS', 'DIESEL']" formControlName="fuel">
          <dx-validator name="Fuel">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Body Type *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="TYPES" formControlName="type" [acceptCustomValue]="true">
          <dx-validator name="Body Type">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Wheel Chair Accessible *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="wheelchairAccessible" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Stretcher Accessible *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="stretcherAccessible" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Has Lift/Ramp *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="HAS_LIFT_RAMP" formControlName="hasLiftRamp">
          <dx-validator name="Has Lift/Ramp">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Passenger Capacity *</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="passengerCapacity">
          <dx-validator name="Passenger Capacity">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">CDL *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="cdl" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Wheelchair Capacity *</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="wheelchairCapacity">
          <dx-validator name="Wheelchair Capacity">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Possible Configs</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="possibleConfigs"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">HVAC Units</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="['Front', 'Front & Back']" formControlName="hvacUnits"></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Emergency Exit Door</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="emergencyExitDoor" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Engine Type</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="engineType"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Tire Size</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="tireSize"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Body Company</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="bodyCompany"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Gross Weight</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="grossWeight"></dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Automatic Emergency Braking / Pre-Collision Assist</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="autoEmBraking" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Lane departure warning / Lane-Keeping System</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="laneDepWarning" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Config Note</div>
      <div class="dx-field-value">
        <dx-text-area formControlName="configNote"></dx-text-area>
      </div>
    </div>
  </div>

  <!-- -->

  <div class="dx-fieldset" *ngIf="selectedTabIdx === 2">
    <div class="dx-fieldset-header">Equipment Fields</div>

    <div class="dx-field">
      <div class="dx-field-label">Seat-belt Cutter *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="seatBeltCutter" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">First Aid Kit *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="firstAidKit" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Bloodborne Pathogen Kit *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="bloodBornePathogenKit" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Emergency Triangles *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="emTriangles" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Umbrella</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="umbrella" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Cleaning Supplies *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="cleaningSupplies" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Broom *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="broom" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Hand Sanitizer Dispenser Installed</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="sanitizerDispenser" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field" *ngIf="form.get('hasLiftRamp').value === 'Electric Lift'">
      <div class="dx-field-label">WC Lift Hand Pump Handle *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="liftHandPumpHandle" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">PPE - Gloves & Mask *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="ppe" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Fire Extinguisher Installed *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="fireExtinguisherInstalled" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Fire Extinguisher Exp. Date</div>
      <div class="dx-field-value">
        <dx-date-box formControlName="fireExtinguisherExp">
          <!--          <dx-validator name="Fire Extinguisher Exp. Date">-->
          <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
          <!--          </dx-validator>-->
        </dx-date-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Fire Extinguisher 2 Installed *</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="fireExtinguisher2Installed" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Fire Extinguisher 2 Exp. Date</div>
      <div class="dx-field-value">
        <dx-date-box formControlName="fireExtinguisher2Exp">
          <!--          <dx-validator name="Fire Extinguisher Exp. Date">-->
          <!--            <dxi-validation-rule type="required"></dxi-validation-rule>-->
          <!--          </dx-validator>-->
        </dx-date-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">WheelChair Hooks Number</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="wcHooksNum"></dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">WheelChair Seat Belts Number</div>
      <div class="dx-field-value">
        <dx-number-box formControlName="wcSeatBeltsNum"></dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Windshield Mount GPS Holder</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="wsMountGPSHolder" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Step Stool</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="stepStool" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Jump Cables</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="jumpCables" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">EZ Pass Transponder</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="ezPassTransponder" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Handheld GPS</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="ezHandGPS" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">SeatBelt Extender</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="seatBeltExt" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Thermometer</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="thermometer" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Kubota Key</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="kubotaKey" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">USB Charger</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="usbCharger" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">USB Charging Wire</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="usbChargingWire" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Foldable Wheelchair</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="foldableWheelchair" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Foldable Transport Chair</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="foldableTransportChair" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Stair Chair (2 Men)</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="stairChair2Men" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Stairway Wheelchair Carrier</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="stairwayWCCarrier" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <!---->
    <br />
    <div class="dx-fieldset-header">Seasonal Winter Equipment</div>

    <div class="dx-field">
      <div class="dx-field-label">Snow Shovel</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="snowShovel" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Windshield Brush</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="wsBrush" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Salt</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="salt" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <!---->
    <br />
    <div class="dx-fieldset-header">Mounted Equipment</div>

    <div class="dx-field">
      <div class="dx-field-label">Back-Up Camera Installed</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="buCameraInstalled" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <br />

    <div class="dx-field">
      <div class="dx-field-label">GPS Unit Installed</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="gpsUnitInstalled" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">GPS Bypass Used</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="gpsBypassUsed" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">GPS Unit Model</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="gpsUnitModel"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">GPS Serial Number</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="gpsSerialNumber"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">GPS Installed Date</div>
      <div class="dx-field-value">
        <dx-date-box formControlName="gpsInstalledDate"></dx-date-box>
      </div>
    </div>

    <br />

    <div class="dx-field">
      <div class="dx-field-label">Windshield Camera Installed</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="cameraInstalled" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Windshield Camera Serial Number</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="cameraSerialNumber"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Windshield Camera Model</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="cameraModel"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Windshield Camera Installed Date</div>
      <div class="dx-field-value">
        <dx-date-box formControlName="cameraInstalledDate"></dx-date-box>
      </div>
    </div>

    <br />

    <div class="dx-field">
      <div class="dx-field-label">Covid19 Shield Installed</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="covid19Shield" switchedOnText="Yes" switchedOffText="No"></dx-switch>
        </div>
      </div>
    </div>
  </div>

  <!-- -->

  <div class="dx-fieldset" *ngIf="selectedTabIdx === 3">
    <!--    <ng-container *ngIf="stateFull">-->
    <ng-container>
      <div class="dx-field">
        <div class="dx-field-label">Last Mechanical Check Date</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="lastMechanicalCheck"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Outside Wash</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="lastOutsideWash"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Inside Cleanup</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="lastInsideCleanup"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Next Transmission Oil Change Mileage</div>
        <div class="dx-field-value">
          <dx-number-box formControlName="nextTransmOilChangeMiles"></dx-number-box>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- -->

  <div class="dx-fieldset" *ngIf="selectedTabIdx === 4">
    <div class="dx-fieldset-header">Financial Fields</div>

    <div class="dx-field">
      <div class="dx-field-label">Current Ownership Status</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="OWNERSHIP_STATUS"
          formControlName="ownershipStatus"
          [showClearButton]="true"></dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Current Acquisition</div>
      <div class="dx-field-value current-acquisition">
        <dx-text-box [value]="getCurrentAcquisitionName()" [readOnly]="true"></dx-text-box>
        <dx-button (onClick)="addAcquisitionForm()">Add Acquisition</dx-button>
      </div>
    </div>

    <hr />

    <app-form-acquisition-array
      [vehicle]="model"
      [formArray]="acquisitionsFormArray"
      [selectedForm]="selectedAcquisitionForm"
      [dealerDso]="dealerDso | async"
      [creditorDso]="creditorDso | async"
      [personDso]="personDso | async"
      (valueChanged)="onAcquisitionFormChanged($event)"
      (validateAsync)="validateAsync()"
      (remove)="removeSelectedAcquisitionForm()"></app-form-acquisition-array>
  </div>

  <!--      -->

  <!--<mat-divider></mat-divider>-->

  <!-- /// -->

  <p>
    <dx-validation-summary></dx-validation-summary>
  </p>

  <!--    <p class="alert-danger" *ngIf="errorMessage">-->
  <!--      {{ errorMessage }}-->
  <!--    </p>-->
</dx-validation-group>


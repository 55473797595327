import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { AutoDispatchLog, Config, Consumer, ConsumerAddressView, TripManifest, } from '../../../../shared/sdk/models';
import { LoggerService, } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { HelperService } from '../../../trip-manifest/services/helper.service';
import Guid from 'devextreme/core/guid';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
export class AutoDispatchDetailsTabAcceptedComponent extends ABaseComponent {
    constructor(logger, ui, dss, dialog, helper, auth) {
        super(logger);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.helper = helper;
        this.auth = auth;
        this.grid_selectedRowKeys = [];
        this.grid_onSelectionChanged = (event) => {
            this.selected = event.selectedRowsData;
        };
        this.status_calculateValue = (rowData) => {
            return rowData.meta && rowData.meta.allowed ? 'On Manifest' : 'Requested';
        };
        this.settings_calculateValue = (rowData) => {
            const { ks, puPlus, doPlus } = rowData.meta || {};
            return [ks && 'KS', puPlus && 'PU+', doPlus && 'DO+'].filter(Boolean).join(', ');
        };
    }
    set message(m) {
        this._message = m;
        this.changeHandle();
    }
    get message() {
        return this._message;
    }
    changeHandle() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.message) {
                this.message.accepted.forEach(s => {
                    s.dt = moment(s.Date, 'MM/DD/YY').format('YYYY-MM-DD');
                });
                const destinations = yield this.dss.getApi(Config).getAllDestinations().toPromise();
                this.destinationsMap = destinations.reduce((p, d) => (d.address ? Object.assign({}, p, { [d.address.split(',')[0]]: d }) : p), {});
                yield this.loadConsumers(this.message.accepted);
                this.dso = this.message.accepted;
            }
        });
    }
    grid_onToolbarPreparing(e) {
        // console.log(e);
        e.toolbarOptions.items.unshift({
            name: 'markAsGranted',
            widget: 'dxButton',
            locateInMenu: 'auto',
            location: 'before',
            sortIndex: 30,
            options: {
                icon: 'check',
                text: 'Mark As Granted (Already added to Manufest)',
                hint: 'Mark As Granted (Already added to Manufest)',
                onClick: () => this.markAsGranted(),
            },
        });
        e.toolbarOptions.items.unshift({
            name: 'addToManifest',
            widget: 'dxButton',
            locateInMenu: 'auto',
            location: 'before',
            sortIndex: 30,
            // showText: 'inMenu',
            options: {
                icon: 'plus',
                text: 'Add Selected Trips To Manifest',
                hint: 'Add Selected Trips To Manifest',
                // disabled: !this.model,
                onClick: () => this.addToManifest(),
            },
        });
    }
    grid_onContextMenuPreparing(e) {
        if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
            this.grid_selectedRowKeys = [e.row.data];
            e.items = [
                {
                    text: `Add To Manifest:  ${e.row.data['APPOINT TIME/RR TIME']} - ${e.row.data.Customer}`,
                    onItemClick: () => this.addToManifest(),
                },
            ];
        }
    }
    grid_onCellPrepared(e) {
        if (e.rowType === 'data' && e.data.meta.allowed)
            e.cellElement.classList.add('cell-green');
    }
    loadManfiests(manifestsMap) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const manifests = yield Promise.all(Object.keys(manifestsMap).map(date => this.helper.api.getCurrentManifest(date).toPromise()));
            manifests.filter(m => m.date).forEach(m => (manifestsMap[m.date] = Object.assign({}, m, { data: [] })));
        });
    }
    getCastomerNameAndAddress(s) {
        const [ln, fn] = s.Customer.split(', ');
        const [pick, drop] = ['Pick Location', 'Drop Location'];
        const [rAddr, adcAddr] = s.meta.isReturn ? [s[drop], s[pick]] : [s[pick], s[drop]];
        const [street, city, state, zip] = rAddr.split(/, ?/);
        const resAddr = { street, city, state, zip };
        return [fn, ln, adcAddr, resAddr];
    }
    createUnexistentConsumers(tenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const customersMap = this.selected.reduce((p, s) => {
                const [fn, ln, , resAddr] = this.getCastomerNameAndAddress(s);
                const sn = s['Scheduling Note'] || (p[s.Customer] && p[s.Customer].sn) || '';
                return Object.assign({}, p, { [s.Customer]: { name: s.Customer, consumer: s.consumer, ln, fn, resAddr, sn } });
            }, {});
            const customers = Object.values(customersMap);
            const consumers = yield Promise.all(customers.map(({ consumer, ln, fn, resAddr, sn }) => {
                if (consumer)
                    return consumer;
                this.dss
                    .getApi(Consumer)
                    .sendEmailAboutPendingConsumer(`${fn} ${ln}`, resAddr, sn, moment().format('MM/DD/YYYY hh:mm A'))
                    .toPromise();
                return this.dss
                    .getApi(Consumer)
                    .myCreateWithRelated({
                    status: 'PENDING',
                    tenantId,
                    person: { firstname: fn, lastname: ln, contact: { addresses: [resAddr] } },
                })
                    .toPromise();
            }));
            consumers.forEach((c, i) => (customersMap[customers[i].name].consumer = c));
            this.selected.forEach(s => (s.consumer = customersMap[s.Customer].consumer));
        });
    }
    loadConsumers(accepted) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const customersMap = accepted.reduce((p, s) => {
                const [fn, ln, adcAddr, resAddr] = this.getCastomerNameAndAddress(s);
                const adc = this.destinationsMap[adcAddr.split(',')[0]] || { short: adcAddr };
                return Object.assign({}, p, { [s.Customer]: { name: s.Customer, ln, fn, resAddr, adc } });
            }, {});
            const customers = Object.values(customersMap);
            const conss = yield Promise.all(customers.map(({ ln, fn, resAddr: { street } }) => this.dss
                .getApi(ConsumerAddressView)
                .find({
                where: {
                    person_lastname: { like: `%${ln}%` },
                    person_firstname: { like: `%${fn}%` },
                    contact_addresses_0_street: { like: `%${street}%` },
                    status: 'ACTIVE',
                },
            }, headersAllTenantsAppend)
                .toPromise()));
            const consumers = conss.map(([c]) => c);
            consumers.forEach((c, i) => (customersMap[customers[i].name].consumer = c));
            accepted.forEach(s => {
                s.consumer = customersMap[s.Customer].consumer;
                s.adc = customersMap[s.Customer].adc;
            });
        });
    }
    pushDataToManifestsMap(manifestsMap) {
        this.selected.forEach(({ meta, dt, consumer, adc, leg }) => {
            const [o, d] = meta.isReturn ? [adc.short, 'RESIDENCE'] : ['RESIDENCE', adc.short];
            const [e, c, v, esc] = [meta.employeeId, consumer.id, meta.vehicleId, meta.esc];
            const [t, at, dot] = [meta.t, meta.at, meta.dot];
            const [tr, b, st, rt, rid] = [leg['Proposed Trip Sequesce'], 'MTM', 'AMB_TRIP', false, meta.tripId];
            manifestsMap[dt].data.push({ t, at, dot, tr, s: 0, rt, rid, o, d, b, st, e, c, v, esc });
        });
    }
    addToManifest() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const tenantId = this.auth.getCurrentTenant();
            const manifestsMap = this.selected.reduce((p, { dt }) => (Object.assign({}, p, { [dt]: { requestID: new Guid().toString(), date: dt, data: [] } })), {});
            yield this.loadManfiests(manifestsMap);
            yield this.createUnexistentConsumers(tenantId);
            this.pushDataToManifestsMap(manifestsMap);
            for (const { id, data } of Object.values(manifestsMap)) {
                if (!id)
                    continue;
                for (const d of data)
                    try {
                        const res = yield this.helper.api.safeSaveRecord(id, d).toPromise();
                        // wait for the response a few seconds
                        // await new Promise(resolve => setTimeout(resolve, 2000));
                        const a = this.message.accepted.find(({ meta }) => meta.tripId === res.rid);
                        if (a)
                            a.meta.allowed = true;
                    }
                    catch (_a) { }
            }
            const manifests = yield Promise.all(Object.values(manifestsMap).map(({ id, requestID, data, date }) => id ? null : this.helper.api.create(new TripManifest({ requestID, date, data })).toPromise()));
            manifests.forEach(m => m &&
                m.data.forEach(d => {
                    const a = this.message.accepted.find(({ meta }) => meta.tripId === d.rid);
                    if (a)
                        a.meta.allowed = true;
                }));
            yield this.updateTripsAllowed();
        });
    }
    markAsGranted() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selected.forEach(({ meta }) => (meta.allowed = true));
            yield this.updateTripsAllowed();
        });
    }
    updateTripsAllowed() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.message.tripsAllowed = this.message.accepted.reduce((p, { meta: { allowed } }) => p + +!!allowed, 0);
            yield this.dss
                .getApi(AutoDispatchLog)
                .updateAll({ id: this.message.id }, { tripsAllowed: this.message.tripsAllowed, accepted: this.message.accepted })
                .toPromise();
            this.grid.instance.refresh();
        });
    }
}

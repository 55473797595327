<app-content-w-header header="Legacy Activity Log">
  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [dataSource]="ds"
    [errorRowEnabled]="true"
    (onInitialized)="grid_onInitialized($event)"
    (onSelectionChanged)="grid_onSelectionChanged($event)"
    (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
    (onCellPrepared)="grid_onCellPrepared($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  >
    <!--    [stateStoring]="grid_stateStoring"-->

    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <!-- region Columns -->

    <dxi-column caption="Include" dataField="include" dataType="boolean"></dxi-column>

    <dxi-column caption="Device Phone" dataField="deviceNum" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Client" name="client" dataField="deviceNum" dataType="string" [allowEditing]="false">
      <dxo-lookup [dataSource]="deviceDso" valueExpr="phoneNumber" [displayExpr]="consDisplayExpr"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Device" name="device" dataField="deviceNum" dataType="string" [allowEditing]="false">
      <dxo-lookup [dataSource]="deviceDso" valueExpr="phoneNumber" [displayExpr]="deviceDisplayExpr"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Client Field" dataField="clientField" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Address Field" dataField="addressField" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="DateTime"
      dataField="datetime"
      dataType="datetime"
      format="shortDateShortTime"
      [allowEditing]="false"
    ></dxi-column>

    <dxi-column caption="Type" dataField="type" dataType="string">
      <dxo-lookup [dataSource]="typeSource" [allowClearing]="true"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Were Emergency Services Dispatched" dataField="wereEmergencyServicesDispatched"
                dataType="boolean"></dxi-column>

    <dxi-column caption="Was The Participant Transported By Emergency Services"
                dataField="wasTheParticipantTransportedByEmergencyServices" dataType="boolean"></dxi-column>

    <dxi-column caption="Provider Comments" dataField="providerComments" dataType="string"
                [allowEditing]="false"
                [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column caption="Tags" dataField="tags" dataType="string"
                [allowEditing]="false"
                [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column caption="Resolution" dataField="resolution.msg" dataType="string"
                [allowEditing]="false"
                [allowHeaderFiltering]="false"
    ></dxi-column>

    <!--    endregion    -->

    <!--  region Templates  -->

    <!--    endregion    -->

  </dx-data-grid>
</app-content-w-header>

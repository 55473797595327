
declare var Object: any;
export interface EmployeeViewInterface {
  "id": number;
  "tenantId": number;
  "tenantIds": any;
  "employeePositionId"?: number;
  "personId": number;
  "userId"?: number;
  "status": string;
  "type"?: string;
  "interviewedAt"?: any;
  "hiredAt"?: any;
  "separatedAt"?: any;
  "allowFastVehicleCheckout"?: number;
  "allowMultiCheckin"?: number;
  "signImgFileId"?: string;
  "notes"?: string;
  "data": any;
  "ctime": Date;
  "utime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "employeePosition_id"?: number;
  "employeePosition_name"?: string;
  "facility_shortname"?: string;
  "user_id"?: number;
  "user_username"?: string;
  "user_email"?: string;
  "person_id"?: number;
  "person_firstname"?: string;
  "person_lastname"?: string;
  "person_middlename"?: string;
  "person_nickname"?: string;
  "person_sex"?: string;
  "person_dob"?: any;
  "person_notes"?: string;
  "person_data"?: any;
  "person_contactId"?: number;
  "person_contact_id"?: number;
}

export class EmployeeView implements EmployeeViewInterface {
  "id": number;
  "tenantId": number;
  "tenantIds": any;
  "employeePositionId": number;
  "personId": number;
  "userId": number;
  "status": string;
  "type": string;
  "interviewedAt": any;
  "hiredAt": any;
  "separatedAt": any;
  "allowFastVehicleCheckout": number;
  "allowMultiCheckin": number;
  "signImgFileId": string;
  "notes": string;
  "data": any;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "dtime_str": string;
  "employeePosition_id": number;
  "employeePosition_name": string;
  "facility_shortname": string;
  "user_id": number;
  "user_username": string;
  "user_email": string;
  "person_id": number;
  "person_firstname": string;
  "person_lastname": string;
  "person_middlename": string;
  "person_nickname": string;
  "person_sex": string;
  "person_dob": any;
  "person_notes": string;
  "person_data": any;
  "person_contactId": number;
  "person_contact_id": number;
  constructor(data?: EmployeeViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeeView`.
   */
  public static getModelName() {
    return "EmployeeView";
  }

  /**
  * This method creates an instance of EmployeeView for dynamic purposes.
  **/
  public static factory(data: EmployeeViewInterface): EmployeeView{
    return new EmployeeView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeeView',
      plural: 'EmployeeViews',
      path: 'EmployeeViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "tenantIds": {
          name: 'tenantIds',
          type: 'any'
        },
        "employeePositionId": {
          name: 'employeePositionId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "interviewedAt": {
          name: 'interviewedAt',
          type: 'any'
        },
        "hiredAt": {
          name: 'hiredAt',
          type: 'any'
        },
        "separatedAt": {
          name: 'separatedAt',
          type: 'any'
        },
        "allowFastVehicleCheckout": {
          name: 'allowFastVehicleCheckout',
          type: 'number'
        },
        "allowMultiCheckin": {
          name: 'allowMultiCheckin',
          type: 'number'
        },
        "signImgFileId": {
          name: 'signImgFileId',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "employeePosition_id": {
          name: 'employeePosition_id',
          type: 'number'
        },
        "employeePosition_name": {
          name: 'employeePosition_name',
          type: 'string'
        },
        "facility_shortname": {
          name: 'facility_shortname',
          type: 'string'
        },
        "user_id": {
          name: 'user_id',
          type: 'number'
        },
        "user_username": {
          name: 'user_username',
          type: 'string'
        },
        "user_email": {
          name: 'user_email',
          type: 'string'
        },
        "person_id": {
          name: 'person_id',
          type: 'number'
        },
        "person_firstname": {
          name: 'person_firstname',
          type: 'string'
        },
        "person_lastname": {
          name: 'person_lastname',
          type: 'string'
        },
        "person_middlename": {
          name: 'person_middlename',
          type: 'string'
        },
        "person_nickname": {
          name: 'person_nickname',
          type: 'string'
        },
        "person_sex": {
          name: 'person_sex',
          type: 'string'
        },
        "person_dob": {
          name: 'person_dob',
          type: 'any'
        },
        "person_notes": {
          name: 'person_notes',
          type: 'string'
        },
        "person_data": {
          name: 'person_data',
          type: 'any'
        },
        "person_contactId": {
          name: 'person_contactId',
          type: 'number'
        },
        "person_contact_id": {
          name: 'person_contact_id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
